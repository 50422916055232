import createApp from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';

export default function EmbedRedirect() {
    const config = {
        // The client ID provided for your application in the Partner Dashboard.
        apiKey: "e81f321eaf4ca36c21090d47f695c86e",
        // The host of the specific shop that's embedding your app. This value is provided by Shopify as a URL query parameter that's appended to your application URL when your app is loaded inside the Shopify admin.
        host: new URLSearchParams(window.location.search).get("host"),
        forceRedirect: false,
    };

    console.log(13, config);

    const app = createApp(config);
    const url = new URLSearchParams(window.location.search).get("url");
    const redirect = Redirect.create(app);

    redirect.dispatch(Redirect.Action.REMOTE, url);
}