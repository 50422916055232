import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import { Context } from "/home/ubuntu/iPopify/src/Context/Context";
import { useContext, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import SearchIcon from '@mui/icons-material/Search';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import UpgradePlanDialog from './CommonComponents/UpgradePlanDialog.react';


const GET_USER_PRODUCT_LIST_COUNT = gql`
  query getUserProductListCount {
    getUserProductListCount
  }
`;

const GET_ORDER_LIST_COUNT = gql`
  query getOrderListCount {
    getOrderListCount
  }
`;

const GET_SHOW_UPGRADE_ICON = gql`
query getShowUpgradeIcon {
  getShowUpgradeIcon
}
`;

export default function BasicList() {
  const { setState, state } = useContext(Context);
  // const { isOpenYellowBanner } = state;
  const storePlatform = localStorage.getItem("storePlatform");
  const navigate = useNavigate();
  const handleClickList = (path) => {
    setState({ ...state, title: "" });
    if (window.location.pathname === path) {
      navigate("/testPathname");
      setTimeout(() => {
        navigate(path);
      }, 10);
    } else {
      navigate(path);
    }
  };

  const siderbarList = [
    //
    {
      icon: <Box sx={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        marginTop: '-12px'
      }}>
        <img
          src="/img/sidebar/icon_onboard.png"
          width="24px"
          height="24px"
          loading={"lazy"}
          alt="sidebar icon"
        />
      </Box>,
      selectIcon: <Box sx={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        marginTop: '-12px'
      }}>
        <img
          src="/img/sidebar/icon_onboard_selected.png"
          width="24px"
          height="24px"
          loading={"lazy"}
          alt="sidebar icon"
        />
      </Box>,
      title: "Onboard",
      path: "/onboard",
      type: "route"
    },
    {
      icon: <Box sx={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        marginTop: '-12px'
      }}>
        <img
          src="/img/sidebar/icon_dsashboard.png"
          width="24px"
          height="24px"
          loading={"lazy"}
          alt="sidebar icon"
        />
      </Box>,
      selectIcon: <Box sx={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        marginTop: '-12px'
      }}>
        <img
          src="/img/sidebar/icon_dsashboard_selected.png"
          width="24px"
          height="24px"
          loading={"lazy"}
          alt="sidebar icon"
        />
      </Box>,
      title: "Dashboard",
      path: "/dashboard",
      type: "route"
    },
    // {
    //   icon: <Box sx={{
    //     width: "24px",
    //     height: "24px",
    //     marginRight: "16px",
    //     marginTop: '-12px'
    //   }}>
    //     <img
    //       src="/img/sidebar/icon_forms.png"
    //       width="24px"
    //       height="24px"
    //       loading={"lazy"}
    //       alt="sidebar icon"
    //     />
    //   </Box>,
    //   selectIcon: <Box sx={{
    //     width: "24px",
    //     height: "24px",
    //     marginRight: "16px",
    //     marginTop: '-12px'
    //   }}>
    //     <img
    //       src="/img/sidebar/icon_forms_selected.png"
    //       width="24px"
    //       height="24px"
    //       loading={"lazy"}
    //       alt="sidebar icon"
    //     />
    //   </Box>,
    //   title: "Forms",
    //   path: "/forms",
    //   type: "route",
    // },
    {
      icon: <SettingsIcon sx={{ color: "#616161", width: '24px', height: '24px', marginRight: "16px" }} />,
      selectIcon: <SettingsIcon sx={{ color: "#1565d8", width: '24px', height: '24px', marginRight: "16px" }} />,
      title: "Settings",
      path: "/settings",
      type: "route"
    }, {
      icon: <MailOutlineIcon sx={{ color: "#616161", width: '24px', height: '24px', marginRight: "16px" }} />,
      selectIcon: <MailOutlineIcon sx={{ color: "#1565d8", width: '24px', height: '24px', marginRight: "16px" }} />,
      title: "Contact Us",
      path: "",
      type: "event",
      function: () => {
        document.querySelector('div[title="Contact us button"]').click();
      }
    }]


  const { pathname } = useLocation();
  return (
    <Box sx={{
      backgroundColor: "#FFF",
      width: "240px",
      height: "100%",
      position: "fixed",
      top: 0,
      left: 0,
      overflow: "auto",
      boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      zIndex: '99'
    }} >
      <Box>
        <Box sx={{
          width: "232px",
          height: "97px",
          display: "flex",
          alignItems: "center",
          padding: '0px 4px 0px 4px',
          cursor: "pointer"
        }} onClick={() => { navigate("/") }}>
          <Box sx={{
            width: "232px",
            height: "57px",
            background: 'url("img/header_logo.png") no-repeat 0 0',
            backgroundSize: 'cover',
          }} />
        </Box>
        {siderbarList.map((siderbar, siderbarIndex) => {
          if (storePlatform == "wix" && siderbar.title == "My Orders") {
            return null;
          }
          return <Box sx={{
            height: '48px',
            lineHeight: '48px',
            padding: '0px 4px 0px 12px',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: '400',
            letterSpacing: '0.15px',
            color: pathname === siderbar.path ? "#1565D8" : "#1C1C1CB2",
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            position: 'relative',
            backgroundColor: pathname === siderbar.path ? "#F5F9FF" : "#FFF",
            "&:hover": {
              backgroundColor: pathname === siderbar.path ? "#D9D9D9" : '#F1F2F3',
            }
          }} key={"siderbar" + siderbarIndex} onClick={() => {
            if (siderbar.type === "route") {
              handleClickList(siderbar.path);
            } else if (siderbar.type === "event") {
              siderbar.function();
            }
          }}>
            {siderbar.path === pathname ? siderbar.selectIcon : siderbar.icon}
            <Box>{siderbar.title}</Box>
            {/* {siderbar.path === "/myProducts" && myProductCountLoading === false && myProductCountData != null && myProductCountData.getUserProductListCount !== -1 ?
                  <Box sx={{
                    height: '20px',
                    maxWidth: '44px',
                    padding: '0 6px',
                    position: 'absolute',
                    top: '14px',
                    right: '5px',
                    borderRadius: '6px',
                    backgroundColor: pathname === "/myProducts" ? '#999999' : '#FF7D45',
                    fontSize: '14px',
                    fontWeight: '400',
                    textAlign: 'center',
                    lineHeight: '20px',
                    color: "#FFF",
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }}>{myProductCountData.getUserProductListCount}</Box>
                  : <Box />
                } */}
            {/* {siderbar.path === "/myOrders" && myOrderCountLoading === false && myOrderCountData != null && myOrderCountData.getOrderListCount !== -1 ?
                  <Box sx={{
                    height: '20px',
                    maxWidth: '44px',
                    padding: '0 6px',
                    position: 'absolute',
                    top: '14px',
                    right: '5px',
                    borderRadius: '6px',
                    backgroundColor: pathname === "/myOrders" ? '#999999' : '#FF7D45',
                    fontSize: '14px',
                    fontWeight: '400',
                    textAlign: 'center',
                    lineHeight: '20px',
                    color: "#FFF",
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }}>{myOrderCountData.getOrderListCount >= 10 ? "10+" : myOrderCountData.getOrderListCount}</Box>
                  : <Box />
                } */}
            {siderbar.type === "route" && siderbar.path === "" ? <Box sx={{
              width: '73px',
              height: '18px',
              borderRadius: '2px',
              background: "#61C454",
              color: '#FFF',
              fontSize: '12px',
              fontWeight: '500',
              letterSpacing: '0.15px',
              textAlign: 'center',
              lineHeight: '18px',
              marginLeft: '20px',
              padding: '0px 10px'
            }}>Coming soon</Box> : null}
          </Box>
        })}
        <Box sx={{ height: '48px', width: '1px' }}></Box>
      </Box>
    </Box >
  )
}