/** @format */

import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { gql, useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { Context } from "./Context/Context";
const CHANGE_SUBSCRIPTION = gql`
  query changeSubscription(
    $timeType: String, 
    $domain: String,
    $redirectURL: String
) {
    changeSubscription(
        timeType: $timeType, 
        domain: $domain
        redirectURL: $redirectURL
    ) {
        status
        confirmationUrl
        message
    }
  }
`;

export default function ChangeSubscription() {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const timeType = queryParams.get('timeType');
    const redirectURL = queryParams.get('redirectURL');
    const [showWrongPage, setShowWrongPage] = React.useState(false);
    console.log(23, timeType, window.location.hostname, redirectURL)
    const { loading, data } = useQuery(CHANGE_SUBSCRIPTION, {
        variables: {
            timeType,
            domain: window.location.hostname,
            redirectURL
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
    });

    React.useEffect(() => {
        if (loading === false) {
            if (data != null && data.changeSubscription != null && data.changeSubscription.status) {
                console.log(40, data.changeSubscription.confirmationUrl)
                window.location.replace(data.changeSubscription.confirmationUrl);
            } else {
                console.log(47)
                setShowWrongPage(true);
            }
        }
    }, [loading]);

    return (
        <>
            <Box sx={{
                height: "400px",
                marginTop: 10,
                display: "flex",
                flexDirection: 'column',
                alignItems: "center",
            }} >
                {!showWrongPage ? <Box sx={{
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: "center"
                }}>
                    <CircularProgress />
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        "& .text": {
                            height: '40px',
                            lineHeight: '40px',
                            color: '#666666',
                            fontWeight: '400',
                            fontSize: '30px',
                            textAlign: 'center'
                        }
                    }}>
                        <Box className="text">Redirecting to Shopify Payment Page ...</Box>
                    </Box>
                </Box> : <Box><Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    "& .text": {
                        height: '40px',
                        lineHeight: '40px',
                        color: '#666666',
                        fontWeight: '400',
                        fontSize: '30px',
                        textAlign: 'center'
                    }
                }}>
                    <Box className="text">Oops! Something went wrong!</Box>
                </Box>
                </Box>}

            </Box>
        </>
    );
}
