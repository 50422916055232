/** @format */

import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Context } from "./Context/Context";
import { Box } from "@mui/system";
import { getCookie } from "doc-cookie";
import client from "@bigdatacloudapi/client";
import { v4 as uuid } from "uuid";
import { gql, useMutation } from "@apollo/client";
import Homepage from "./HomepageComponents/Homepage.react";
import Dashboard from "./DashboardComponents/Dashboard.react";
import Forms from "./FormsComponents/Forms.react";
import Setting from "./SettingComponents/Setting.react";
import ShopifyInstall from "./ShopifyInstall.react";
import ShopifyRedirect from "./ShopifyRedirect.react";
import ShopifyAuth from "./ShopifyAuth.react";
import ShopifyPurchaseRedirect from "./ShopifyPurchaseRedirect.react";
import AccountPopUpDialog from "./CommonComponents/AccountPopUpDialog.react";
import ChangeSubscription from "./ChangeSubscription.react";
import SnackbarComponent from "./CommonComponents/SnackbarComponent.react";
import Privacy from "./Privacy.react";
import ExplanatoryVideoDialog from "./CommonComponents/ExplanatoryVideoDialog.react";
import EmbedRedirect from "./EmbedRedirect.react";

const LOG = gql`
mutation log(
  $userEmail: String
  $eventName: String
  $eventValue: String
  $sessionID: String
  $IP: String
  $addOn: String
  $ref: String
  $platform: String
) {
  log(
    userEmail: $userEmail
    eventName: $eventName
    eventValue: $eventValue
    sessionID: $sessionID
    IP: $IP
    addOn: $addOn
    ref: $ref
    platform: $platform
  )
}
`;

export default function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const subscriptionStatus = queryParams.get('subscriptionStatus');

  useEffect(() => {
    const token = getCookie("token");
    if ((token == null ||
      token === "null" ||
      token === "undefined" ||
      token === "") &&
      localStorage.getItem("firstName") != null
    ) {
      localStorage.clear();
      window.location.href = "/";
    }
  }, []);

  const [state, setState] = useState({
    firstName:
      localStorage.getItem("firstName") === "null"
        ? null
        : localStorage.getItem("firstName"),
    shopID:
      localStorage.getItem("shopID") === "null"
        ? '0'
        : localStorage.getItem("shopID"),
    email:
      localStorage.getItem("email") === "null"
        ? null
        : localStorage.getItem("email"),
    shopName:
      localStorage.getItem("shopName") === "null"
        ? null
        : localStorage.getItem("shopName"),
    subscriptionMonthlyURL: localStorage.getItem("subscriptionMonthlyURL") === "null"
      ? null
      : localStorage.getItem("subscriptionMonthlyURL"),
    subscriptionYearlyURL: localStorage.getItem("subscriptionYearlyURL") === "null"
      ? null
      : localStorage.getItem("subscriptionYearlyURL"),
    openSnackbar: subscriptionStatus === "M1" || subscriptionStatus === "Y1" || subscriptionStatus === "0",
    snackbarText: subscriptionStatus === "M1" ? "Success, Upgrade to Pro Monthly Plan." : (subscriptionStatus === "Y1" ? "Success, Upgrade to Pro Yearly Plan." : (subscriptionStatus === "0" ? "Success, Upgrade to Pro Yearly Plan." : "Oops, Something unexpected happens.")),
    snackbarState: subscriptionStatus === "M1" || subscriptionStatus === "Y1" ? "SUCCESS" : (subscriptionStatus === "0" ? "FAILED" : null),
    snackbarPosition: window.location.pathname == "settings" ? "calc(50% + 120px)" : "50%",
    currentComponentName: null,
    isAccountPopUpDialogOpen: false,
    isExplanatoryVideoDialogOpen: false,
    explanatoryVideoSrc: '',
    // sessionID,
    // IP,
    // sendLog,
    // adjustedEmail: null
  });

  return (
    <Box>
      <Context.Provider value={{ state, setState }}>
        <BrowserRouter>
          <AccountPopUpDialog />
          <SnackbarComponent />
          <ExplanatoryVideoDialog />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <React.Suspense fallback={null}>
                  <Homepage />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/onboard"
              element={
                <React.Suspense fallback={null}>
                  <Homepage />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/dashboard"
              element={
                <React.Suspense fallback={null}>
                  <Dashboard />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/embedRedirect"
              element={
                <React.Suspense fallback={null}>
                  <EmbedRedirect />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/forms"
              element={
                <React.Suspense fallback={null}>
                  <Forms />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/settings"
              element={
                <React.Suspense fallback={null}>
                  <Setting />
                </React.Suspense>
              }
            />
            <Route
              path="/shopifyRedirect"
              element={
                <React.Suspense fallback={null}>
                  <ShopifyRedirect />
                </React.Suspense>
              }
            />
            <Route
              path="/privacy"
              element={
                <React.Suspense fallback={null}>
                  <Privacy />
                </React.Suspense>
              }
            />
            <Route
              path="/shopifyAuth"
              element={
                <React.Suspense fallback={null}>
                  <ShopifyAuth />
                </React.Suspense>
              }
            />
            <Route
              path="/shopifyInstall"
              element={
                <React.Suspense fallback={null}>
                  <ShopifyInstall />
                </React.Suspense>
              }
            />
            <Route
              path="/shopifyPurchaseRedirect"
              element={
                <React.Suspense fallback={null}>
                  <ShopifyPurchaseRedirect />
                </React.Suspense>
              }
            />
            <Route
              path="/changeSubscription"
              element={
                <React.Suspense fallback={null}>
                  <ChangeSubscription />
                </React.Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      </Context.Provider>
    </Box>
  );
}
