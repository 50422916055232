import React, { createContext, useReducer } from 'react';

export const initialState = {
    firstName: null,
    lastName: null,
    email: null,
}

export const Context = createContext({});

export const actions = {
    SET: "SET",
    ADD_USER_INFO: "ADD_USER_INFO",
};

export const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET:
            return action.value;
        case actions.ADD_USER_INFO:
            console.log(action);
            const { firstName, shopifyToken } = action.value;
            return {
                firstName,
                shopifyToken,
                ...state,
            }
        default:
            return state;
    }
};