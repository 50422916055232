/** @format */
import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { Context } from "../Context/Context";
import AppSidebar from "../AppSidebar.react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "doc-cookie";
import * as echarts from 'echarts';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { getCookie } from "doc-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const GET_DASHBOARD_META_DATA = gql`
  query getDashboardMetaData($timeRange: String) {
    getDashboardMetaData(timeRange: $timeRange) {
      viewsCount
      newCustomersCount
      loginsCount
      ordersCount
      priorViewsCount
      priorNewCustomersCount
      priorLoginsCount
      viewsChartDataPoints {
        name
        count
      }
      priorViewsChartDataPoints {
        name
        count
      }
      viewsInAppBrowserNameDataPoints {
        name
        count
      }
      viewsOSDataPoints {
        name
        count
      }
      viewsBrowserNameDataPoints {
        name
        count
      }
      realTimeEvents {
        id
        date
        IP
        firstName
        email
        eventName
      }
    }
  }
`;
const GET_DASHBOARD_TABLE = gql`
  mutation getDashboardTable($timeRange: String, $type: String, $eventName: String) {
    getDashboardTable(timeRange: $timeRange, type: $type, eventName: $eventName) {
      name
      count
    }
  }
`;

const ButtonTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement={placement ? placement : "bottom"} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
    fontSize: "20px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    color: "#FFF",
    maxWidth: '260px',
    fontSize: "12px",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 1px rgb(0 0 0 / 12%)",
  },
}));

const getButtonTooltipLayout = (title) => {
  return <React.Fragment>
    <Box sx={{
      color: '#FFF',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '150%'
    }}>
      {title}
    </Box>
  </React.Fragment>
}

const timeframeData = [{
  value: "24_HOURS",
  option: "Last 24 Hours"
}, {
  value: "7_DAYS",
  option: "Last 7 Days"
}, {
  value: "30_DAYS",
  option: "Last 30 Days"
}, {
  value: "90_DAYS",
  option: "Last 90 Days"
}]
let brokenLineGraph, pieChart, histogram, trafficByApp;
const color = ['#f495fc', '#62c554', '#93deff', '#1565d8', '#ffc728', '#95a4fc'];
export default function Dashboard() {
  const navigate = useNavigate();
  const token = getCookie("token");

  React.useEffect(() => {
    if (token == null || localStorage.getItem("shopName") == null || localStorage.getItem("shopName") == "null") {
      navigate("/");
    }
  }, [navigate, token]);
  const { state, setState } = React.useContext(Context)
  const { shopName, firstName, email } = state;
  const [openMenu, setOpenMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const firstNameBox = React.useRef(null);
  const storePlatform = localStorage.getItem("storePlatform");
  const [chartPointsData, setChartPointsData] = React.useState();
  const [priorChartPointshData, setPriorChartPointshData] = React.useState();
  const [AppPointsData, setAppPointsData] = React.useState();
  const [OSPointsData, setOSPointsData] = React.useState();
  const [OSPointsDataXAxis, setOSPointsDataXAxis] = React.useState();
  const [BrowserNameData, setBrowserNameData] = React.useState();
  const [isShowBrokenLineGraphData1, setIsShowBrokenLineGraphData1] = React.useState(true);
  const [isShowBrokenLineGraphData2, setIsShowBrokenLineGraphData2] = React.useState(true);

  const [chartPointsIsNull, setChartPointsIsNull] = React.useState(false);
  const [AppPointsIsNull, setAppPointsIsNull] = React.useState(false);
  const [OSPointsIsNull, setOSPointsIsNull] = React.useState(false);
  const [BrowserNameIsNull, setBrowserNameIsNull] = React.useState(false);

  const [chartPointsLoading, setChartPointsLoading] = React.useState(false);
  const [AppPointsLoading, setAppPointsLoading] = React.useState(false);
  const [OSPointsLoading, setOSPointsLoading] = React.useState(false);
  const [BrowserNameLoading, setBrowserNameLoading] = React.useState(false);

  const [viewChartEventName, setViewChartEventName] = React.useState("VIEWS");
  const [viewAppEventName, setViewAppEventName] = React.useState("VIEWS");
  const [viewOSEventName, setViewOSEventName] = React.useState("VIEWS");
  const [viewBrowserNameEventName, setViewBrowserNameEventName] = React.useState("VIEWS");


  const [timeRange, setTimeRange] = React.useState("24_HOURS");
  const [timeRangeOption, setTimeRangeOption] = React.useState("Last 24 Hours");
  const [boxSwitch, setBoxSwitch] = React.useState(false);

  const [get_dashboard_table] = useMutation(GET_DASHBOARD_TABLE);
  const { loading, data, refetch } = useQuery(GET_DASHBOARD_META_DATA, {
    variables: {
      timeRange: "24_HOURS",
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const handleGetDashBoardTable = (type, eventName) => {
    get_dashboard_table({
      variables: {
        timeRange,
        type,
        eventName
      },
    }).then(res => {
      if (res.data != null && res.data.getDashboardTable != null) {
        switch (type) {
          case "CHART":
            brokenLineGraph.dispose();
            const resultData = res.data.getDashboardTable;
            const firstPointList = resultData.slice(0, resultData.length / 2);
            const secondPointList = resultData.slice(resultData.length / 2);
            setChartPointsData(firstPointList);
            setPriorChartPointshData(secondPointList);
            setChartPointsLoading(false);
            setChartPointsIsNull(false);
            break;
          case "OS":
            histogram.dispose();
            const newOSPointsDataXAxis = [];
            const newOSPointsData = res.data.getDashboardTable.map((item, index) => {
              newOSPointsDataXAxis.push(item.name != null ? item.name : "Others")
              return {
                value: item.count,
                itemStyle: {
                  color: color[index]
                }
              };
            })
            setOSPointsData(newOSPointsData);
            setOSPointsDataXAxis(newOSPointsDataXAxis);
            setOSPointsLoading(false);
            setOSPointsIsNull(false);
            break;
          case "BROWSER":
            pieChart.dispose();
            const newBrowserNameData = res.data.getDashboardTable.map((item, index) => {
              return { value: item.count, name: item.name != null ? item.name : "Others" };
            });
            setBrowserNameData(newBrowserNameData);
            setBrowserNameLoading(false);
            setBrowserNameIsNull(false);
            break;
          case "IN_APP_BROWSER_NAME":
            trafficByApp.dispose();
            const newAppPointsData = res.data.getDashboardTable.map((item, index) => {
              return { value: item.count, name: item.name != null ? item.name : "Others" };
            })
            setAppPointsData(newAppPointsData);
            setAppPointsLoading(false);
            setAppPointsIsNull(false);
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case "CHART":
            setChartPointsIsNull(true);
            setChartPointsLoading(false);
            break;
          case "OS":
            setOSPointsIsNull(true);
            setOSPointsLoading(false);
            break;
          case "BROWSER":
            setBrowserNameIsNull(true);
            setBrowserNameLoading(false);
            break;
          case "IN_APP_BROWSER_NAME":
            setAppPointsIsNull(true);
            setAppPointsLoading(false);
            break;
          default:
            break;
        }
      }
    })
  }
  React.useEffect(() => {
    if (!loading) {
      if (data != null && data.getDashboardMetaData != null) {
        if (data.getDashboardMetaData.viewsChartDataPoints == null) {
          setChartPointsIsNull(true);
        } else {
          const resultData = data.getDashboardMetaData.viewsChartDataPoints;
          const firstPointList = resultData.slice(0, resultData.length / 2);
          const secondPointList = resultData.slice(resultData.length / 2);
          setChartPointsData(firstPointList);
          setPriorChartPointshData(secondPointList);
          setChartPointsLoading(false);
        }
        if (data.getDashboardMetaData.viewsOSDataPoints == null) {
          setOSPointsIsNull(true);
        } else {
          const newOSPointsDataXAxis = [];
          const newOSPointsData = data.getDashboardMetaData.viewsOSDataPoints.map((item, index) => {
            newOSPointsDataXAxis.push(item.name != null ? item.name : "Others")
            return {
              value: item.count,
              itemStyle: {
                color: color[index]
              }
            };
          })
          setOSPointsData(newOSPointsData);
          setOSPointsDataXAxis(newOSPointsDataXAxis);
          setOSPointsLoading(false);
        }
        if (data.getDashboardMetaData.viewsBrowserNameDataPoints == null) {
          setBrowserNameIsNull(true);
        } else {
          const newBrowserNameData = data.getDashboardMetaData.viewsBrowserNameDataPoints.map((item, index) => {
            return { value: item.count, name: item.name != null ? item.name : "Others" };
          });
          setBrowserNameData(newBrowserNameData);
          setBrowserNameLoading(false);
        }
        if (data.getDashboardMetaData.viewsInAppBrowserNameDataPoints == null) {
          setAppPointsIsNull(true);
        } else {
          const newAppPointsData = data.getDashboardMetaData.viewsInAppBrowserNameDataPoints.map((item, index) => {
            return { value: item.count, name: item.name != null ? item.name : "Others" };
          })
          setAppPointsData(newAppPointsData);
          setAppPointsLoading(false);
        }
      } else {
        setChartPointsIsNull(true);
        setOSPointsIsNull(true);
        setBrowserNameIsNull(true);
        setAppPointsIsNull(true);
      }
    }
  }, [loading])
  React.useEffect(() => {
    if ((!loading && data != null && data.getDashboardMetaData != null && data.getDashboardMetaData.viewsChartDataPoints != null) || (chartPointsData != null && chartPointsData.length > 0 && priorChartPointshData != null && chartPointsData.length > 0)) {
      brokenLineGraph = echarts.init(document.getElementById('brokenLineGraph'), null, {
        width: "711px",
        height: "500px"
      });
      brokenLineGraph.clear();
      const xAxis = [];
      const brokenLineGraphDefaultData1 = chartPointsData != null && (chartPointsData.length > 0 || !chartPointsIsNull) ? chartPointsData.map((item, index) => {
        if (index < 14) {
          xAxis.push(item.name)
        }
        return item.count;
      }) : data.getDashboardMetaData.viewsChartDataPoints.map(item => {
        xAxis.push(item.name)
        return item.count;
      })
      const brokenLineGraphDefaultData2 = priorChartPointshData != null && (chartPointsData.length > 0 || !chartPointsIsNull) ? priorChartPointshData.map((item, index) => {
        return item.count;
      }) : data.getDashboardMetaData.viewsChartDataPoints.map(item => {
        return item.count;
      })
      let seriesData, legendData;
      if (isShowBrokenLineGraphData1 && isShowBrokenLineGraphData2) {
        legendData = ["The " + timeRangeOption, "The Prior " + timeRangeOption];
        seriesData = [
          {
            data: brokenLineGraphDefaultData1,
            type: 'line',
            smooth: true,
            symbol: "none",
            lineStyle: {
              color: "#00000066"
            }
          },
          {
            data: brokenLineGraphDefaultData2,
            type: 'line',
            smooth: true,
            symbol: "none",
            lineStyle: {
              type: "dotted",
              color: "#a8c5da"
            }
          }
        ];
      } else if (isShowBrokenLineGraphData1) {
        legendData = ["The " + timeRangeOption];
        seriesData = [
          {
            data: brokenLineGraphDefaultData1,
            type: 'line',
            smooth: true,
            symbol: "none",
            lineStyle: {
              color: "#00000066"
            }
          }
        ];
      } else if (isShowBrokenLineGraphData2) {
        legendData = ["The Prior " + timeRangeOption];
        seriesData = [
          {
            data: brokenLineGraphDefaultData2,
            type: 'line',
            smooth: true,
            symbol: "none",
            lineStyle: {
              type: "dotted",
              color: "#a8c5da"
            },
          }
        ];
      } else {
        legendData = [];
        seriesData = [];
      }
      if (!chartPointsIsNull) {
        brokenLineGraph.setOption({
          xAxis: {
            data: xAxis,
            boundaryGap: false,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }, axisLabel: {
              show: true,
              interval: 0,
              rotate: 60,
              inside: false,
              margin: 6,
            },
          },
          grid: {
            bottom: "20%",
          },
          tooltip: {
            trigger: "axis",
          },
          color: ["#00000066", "#a8c5da"],
          yAxis: {
            splitLine: {
              show: false
            },
            scale: true
          },
          series: seriesData
        });
      } else {
        brokenLineGraph.dispose();
      }
    }
  }, [loading, isShowBrokenLineGraphData1, isShowBrokenLineGraphData2, chartPointsData, priorChartPointshData])
  React.useEffect(() => {
    if ((!loading && data != null && data.getDashboardMetaData != null && data.getDashboardMetaData.viewsOSDataPoints != null) || (OSPointsData != null && (OSPointsData.length > 0 || !OSPointsIsNull))) {
      histogram = echarts.init(document.getElementById('histogram'), null, {
        width: "598px",
        height: "200px"
      });
      const xAxis = [];
      const histogramhData = OSPointsData != null && (OSPointsData.length > 0 || !OSPointsIsNull) ? OSPointsData : data.getDashboardMetaData.viewsOSDataPoints.map((item, index) => {
        xAxis.push(item.name != null ? item.name : "Others")
        return {
          value: item.count,
          itemStyle: {
            color: color[index]
          }
        };
      })
      histogram.setOption({
        tooltip: {
          trigger: 'item'
        },
        grid: {
          top: "20px",
          left: "50px",
          right: "20px",
          bottom: "30px"
        },
        xAxis: {
          data: OSPointsDataXAxis != null && OSPointsDataXAxis.length > 0 ? OSPointsDataXAxis : xAxis,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        color: ['#f495fc', '#62c554', '#93deff', '#1565d8', '#ffc728', '#95a4fc'],
        yAxis: {
          splitLine: {
            show: false
          },
        },
        series: [{
          type: 'bar',
          color: ['#f495fc', '#62c554', '#93deff', '#1565d8', '#ffc728', '#95a4fc'],
          barWidth: "28px",
          itemStyle: {
            normal: {
              barBorderRadius: [4, 4, 4, 4],
            }
          },
          data: histogramhData
        },
        ]
      })
    }
  }, [loading, OSPointsData, OSPointsDataXAxis])
  React.useEffect(() => {
    if ((!loading && data != null && data.getDashboardMetaData != null && data.getDashboardMetaData.viewsBrowserNameDataPoints != null) || (BrowserNameData != null && (BrowserNameData.length > 0 || !BrowserNameIsNull))) {
      pieChart = echarts.init(document.getElementById('pieChart'), null, {
        width: "380px",
        height: "200px"
      });
      const pieChartData = BrowserNameData != null && (BrowserNameData.length > 0 || !BrowserNameIsNull) ? BrowserNameData : data.getDashboardMetaData.viewsBrowserNameDataPoints.map((item, index) => {
        return { value: item.count, name: item.name != null ? item.name : "Others" };
      })
      if (pieChartData.length > 0) {
        pieChart.setOption({
          legend: {
            orient: 'vertical',
            right: 10,
            y: 'center',
            icon: 'circle',
            formatter: function (name) {
              let total = 0
              let target
              for (let i = 0; i < pieChartData.length; i++) {
                total += pieChartData[i].value
                if (pieChartData[i].name === name) {
                  target = pieChartData[i].value
                }
              }
              var arr = [
                '{a|' + name + '}',
                '{b|' + ((target / total) * 100).toFixed(2) + '%}',
              ]
              return arr.join('  ')
            },
            textStyle: {
              padding: [8, 0, 0, 0],
              rich: {
                a: {
                  fontSize: 15,
                  width: 110
                },
                b: {
                  fontSize: 15,
                  width: 70,
                  color: '#c1c1c1'
                },
                c: {
                  fontSize: 15
                }
              }
            }
          },
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              center: ['20%', '50%'],
              type: 'pie',
              radius: ['35%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              labelLine: {
                show: false
              },
              itemStyle: {
                borderWidth: 2,
                borderColor: 'white',
                borderRadius: "4px"
              },
              data: pieChartData,

            }
          ]
        })
      } else {
        pieChart.dispose();
      }
    }
  }, [loading, BrowserNameData])
  React.useEffect(() => {
    if ((!loading && data != null && data.getDashboardMetaData != null && data.getDashboardMetaData.viewsInAppBrowserNameDataPoints != null) || (AppPointsData != null && (AppPointsData.length > 0 || !AppPointsIsNull))) {
      trafficByApp = echarts.init(document.getElementById('traffic_by_app'), null, {
        width: "315px",
        height: "500px"
      });
      const trafficByAppData = AppPointsData != null && (AppPointsData.length > 0 || !AppPointsIsNull) ? AppPointsData : data.getDashboardMetaData.viewsInAppBrowserNameDataPoints.map((item, index) => {
        return { value: item.count, name: item.name != null ? item.name : "Others" };
      })
      if (trafficByAppData.length > 0) {
        trafficByApp.setOption({
          legend: {
            orient: 'vertical',
            bottom: 10,
            x: 'center',
            icon: 'circle',
            formatter: function (name) {
              let total = 0
              let target
              for (let i = 0; i < trafficByAppData.length; i++) {
                total += trafficByAppData[i].value
                if (trafficByAppData[i].name === name) {
                  target = trafficByAppData[i].value
                }
              }
              var arr = [
                '{a|' + name + '}',
                '{b|' + ((target / total) * 100).toFixed(2) + '%}',
              ]
              return arr.join('  ')
            },
            textStyle: {
              padding: [8, 0, 0, 0],
              rich: {
                a: {
                  fontSize: 15,
                  width: 110
                },
                b: {
                  fontSize: 15,
                  width: 70,
                  color: '#c1c1c1'
                },
                c: {
                  fontSize: 15
                }
              }
            }
          },
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              center: ['50%', '40%'],
              type: 'pie',
              radius: ['35%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              labelLine: {
                show: false
              },
              itemStyle: {
                borderWidth: 2,
                borderColor: 'white',
                borderRadius: "4px"
              },
              data: trafficByAppData,

            }
          ]
        })
      } else {
        trafficByApp.dispose();
      }
    }
  }, [loading, AppPointsData])

  return (
    <>
      <Box>
        <AppSidebar />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "240px" }}></Box>
          <Box sx={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{
              height: "97px",
              width: "100%",
              boxSizing: "border-box",
              borderBottom: "1px solid #dbdbdb",
              display: 'flex',
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 30px"
            }}>
              <Box sx={{
                fontSize: "24px",
                lineHeight: "36px",
                letterSpacing: "0.15px",
                fontWeight: "700",
                color: "#000000DE"
              }}>Dashboard</Box>
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  width: "182px",
                  height: "50px",
                  marginLeft: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontSize: "14px",
                  color: '#666666'
                }}
              >
                <Box>
                  <Box
                    aria-controls={openMenu ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={(e) => {
                      setOpenMenu(true);
                      setAnchorEl(e.currentTarget)
                    }}
                    ref={firstNameBox}
                    sx={{ display: "flex", alignItems: "center" }}
                  ><img
                      src="/img/shopify_logo.png"
                      height="50px"
                      loading={"lazy"}
                      alt="shopify logo"
                    />
                    {
                      shopName != null ? <Box>
                        {shopName.replace("myshopify.com", "")}
                        {shopName.includes("myshopify.com") ? <Box>myshopify.com</Box> : null}
                      </Box> : firstName
                    }
                  </Box>
                  <Menu
                    sx={{
                      "& .MuiList-root.MuiList-padding.MuiMenu-list": {
                        width: "220px"
                      }
                    }}
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={(e) => {
                      e.stopPropagation();
                      setAnchorEl(null)
                      setOpenMenu(false);
                    }}
                    TransitionComponent={Fade}
                  >
                    <Box sx={{
                      width: '220px',
                      minHeight: '68px',
                      padding: "8px 16px",
                      boxSizing: "border-box",
                      borderBottom: "1px solid #e0e0e0",
                      "&>div": {
                        lineHeight: '23px',
                      }
                    }}>
                      <Box sx={{
                        fontSize: '16px',
                        color: "rgba(0, 0, 0, 0.87)"
                      }}>{firstName}</Box>
                      <Box sx={{
                        fontSize: '14px',
                        color: "rgba(0, 0, 0, 0.6)"
                      }}>{email}</Box>
                    </Box>
                    <MenuItem onClick={(e) => {
                      e.stopPropagation();
                      navigate("/settings");
                      setAnchorEl(null);
                      setOpenMenu(false);
                    }} sx={{ fontSize: '14px' }}>
                      <SettingsIcon sx={{
                        width: "24px",
                        height: "24px",
                        marginRight: "32px",
                        color: "rgba(0, 0, 0, 0.56)"
                      }} />Setting</MenuItem>
                    <MenuItem onClick={(e) => {
                      e.stopPropagation();
                      localStorage.removeItem("email");
                      localStorage.removeItem("firstName");
                      localStorage.removeItem("shopName");
                      localStorage.removeItem("shopifyToken");
                      removeCookie('token');
                      localStorage.clear();
                      window.location.href = "/";
                      setAnchorEl(null);
                      setOpenMenu(false);
                    }} sx={{ fontSize: '14px' }}>
                      <LogoutIcon sx={{
                        width: "24px",
                        height: "24px",
                        marginRight: "32px",
                        color: "rgba(0, 0, 0, 0.56)"
                      }} />Sign Out</MenuItem>
                  </Menu>
                </Box>
                <Box sx={{ color: "#707070" }} onClick={() => {
                  firstNameBox.current.click();
                }}>
                  {openMenu ? (
                    <ArrowDropUpIcon sx={{ fontSize: "28px" }} />
                  ) : (
                    <ArrowDropDownIcon sx={{ fontSize: "28px" }} />
                  )}
                </Box>
              </Box>
            </Box>
            <Box>
              <Box sx={{ position: 'relative', marginTop: "20px", padding: "0px 30px" }}>
                <Box sx={{
                  border: '1px solid #EFF0F6',
                  width: '282px',
                  height: '40px',
                  borderRadius: '8px',
                  padding: '0 12px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  boxSizing: 'border-box',
                  fontSize: '16px',
                  background: "#FFF"
                }} onClick={() => {
                  setBoxSwitch(true);
                }}>
                  <Box sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Timeframe: {timeRangeOption}</Box>
                  {boxSwitch ? <ExpandLessIcon sx={{ fontSize: '28px' }} /> : <ExpandMoreIcon sx={{ fontSize: '28px' }} />}
                </Box>
                {boxSwitch ?
                  <Box sx={{
                    width: '282px',
                    height: 'auto',
                    maxHeight: '300px',
                    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    padding: '10px 0',
                    position: 'absolute',
                    left: '30px',
                    top: '40px',
                    zIndex: 99999,
                    cursor: 'pointer',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}>
                    {timeframeData.map((item, index) => {
                      return (
                        <Box sx={{
                          padding: '4px 10px',
                          backgroundColor: item.option === timeRangeOption ? '#e0e0e0' : '#FFF',
                          '&:hover': {
                            backgroundColor: '#f5f5f5'
                          }
                        }} onClick={() => {
                          setTimeRange(item.value);
                          setBoxSwitch(false);
                          setTimeRangeOption(item.option);
                          refetch({
                            timeRange: item.value,
                          });
                          setChartPointsIsNull(false);
                          setOSPointsIsNull(false);
                          setBrowserNameIsNull(false);
                          setAppPointsIsNull(false);
                          setViewChartEventName("VIEWS");
                          setViewAppEventName("VIEWS");
                          setViewOSEventName("VIEWS");
                          setViewBrowserNameEventName("VIEWS");
                          setChartPointsData([]);
                          setPriorChartPointshData([]);
                          setAppPointsData([]);
                          setOSPointsData([]);
                          setOSPointsDataXAxis([]);
                          setBrowserNameData([]);
                          setChartPointsData([]);
                          setPriorChartPointshData([]);
                        }} key={index}>{item.option}</Box>
                      )
                    })}

                  </Box>
                  : null
                }
                {boxSwitch ?
                  <Box sx={{ width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: '99998' }} onClick={() => { setBoxSwitch(false); }}>
                  </Box>
                  : null
                }
              </Box>
              {loading === false && data != null && data.getDashboardMetaData != null ? <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: "37px",
                padding: "0px 30px",
                marginTop: '20px',
                "& .box": {
                  borderRadius: "16px",
                  background: "#FFF",
                  padding: "24px",
                  border: "1px solid #EFF0F6",
                  width: "250px",
                  height: "112px",
                  boxSizing: "border-box",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "18px",
                  color: "#1C1C1C",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column"
                }
              }}>
                <Box className="box">
                  <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                    <Box sx={{ cursor: "pointer" }}>iPopify Views</Box>
                    <ButtonTooltip title={getButtonTooltipLayout("The total number of times iPopify iframe, and popup are rendered to anonymous users.")} arrow>
                      <Box sx={{
                        width: '15px',
                        height: '15px',
                        background: 'url("img/icon_info.png") no-repeat 0 0',
                        backgroundSize: 'contain',
                      }} />
                    </ButtonTooltip>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                    <Box sx={{ fontWeight: "600", fontSize: "24px", lineHeight: "36px" }}>{data.getDashboardMetaData.viewsCount}</Box>
                    <Box sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      fontSize: "12px"
                    }}>
                      <Box>{data.getDashboardMetaData.priorViewsCount - data.getDashboardMetaData.viewsCount > 0 ? "-" : "+"}</Box>
                      <Box>{data.getDashboardMetaData.priorViewsCount}</Box>
                      <Box sx={{
                        width: '13px', height: '8px', marginLeft: "6px", background: data.getDashboardMetaData.priorViewsCount - data.getDashboardMetaData.viewsCount > 0 ? 'url("img/icon_decline.png") no-repeat 0 0' : 'url("img/icon_increase.png") no-repeat 0 0', backgroundSize: 'cover',
                      }} />
                    </Box>
                  </Box>
                </Box>
                <Box className="box" onClick={() => {
                  if (shopName == null) {
                    return;
                  }
                  window.open(`https://admin.shopify.com/store/${shopName.replace('.myshopify.com', '')}/customers?segment_query=created_by_app_id%20%3D%2083582943233%20`);
                }} sx={{ cursor: "pointer" }}>
                  <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                    <Box sx={{ cursor: "pointer", textDecoration: "underline" }}>iPopify New Customers</Box>
                    <ButtonTooltip title={getButtonTooltipLayout("The number of unique customers who have registered by the iPopify iframe and popup.")} arrow>
                      <Box sx={{
                        width: '15px',
                        height: '15px',
                        background: 'url("img/icon_info.png") no-repeat 0 0',
                        backgroundSize: 'contain',
                      }} />
                    </ButtonTooltip>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                    <Box sx={{ fontWeight: "600", fontSize: "24px", lineHeight: "36px" }}>{data.getDashboardMetaData.newCustomersCount}</Box>
                    <Box sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      fontSize: "12px"
                    }}>
                      <Box>{data.getDashboardMetaData.priorNewCustomersCount - data.getDashboardMetaData.newCustomersCount > 0 ? "-" : "+"}</Box>
                      <Box>{data.getDashboardMetaData.priorNewCustomersCount}</Box>
                      <Box sx={{
                        width: '13px', height: '8px', marginLeft: "6px", background: data.getDashboardMetaData.priorNewCustomersCount - data.getDashboardMetaData.newCustomersCount > 0 ? 'url("img/icon_decline.png") no-repeat 0 0' : 'url("img/icon_increase.png") no-repeat 0 0', backgroundSize: 'cover',
                      }} />
                    </Box>
                  </Box></Box>
                <Box className="box">
                  <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                    <Box sx={{ cursor: "pointer" }}>iPopify Logins</Box>
                    <ButtonTooltip title={getButtonTooltipLayout("The total number of user logins executed through interactions with iPopify iframes or popups.")} arrow>
                      <Box sx={{
                        width: '15px',
                        height: '15px',
                        background: 'url("img/icon_info.png") no-repeat 0 0',
                        backgroundSize: 'contain',
                      }} />
                    </ButtonTooltip>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                    <Box sx={{ fontWeight: "600", fontSize: "24px", lineHeight: "36px" }}>{data.getDashboardMetaData.loginsCount}</Box>
                    <Box sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      fontSize: "12px"
                    }}>
                      <Box>{data.getDashboardMetaData.priorLoginsCount - data.getDashboardMetaData.loginsCount > 0 ? "-" : "+"}</Box>
                      <Box>{data.getDashboardMetaData.priorLoginsCount}</Box>
                      <Box sx={{
                        width: '13px', height: '8px', marginLeft: "6px", background: data.getDashboardMetaData.priorLoginsCount - data.getDashboardMetaData.loginsCount > 0 ? 'url("img/icon_decline.png") no-repeat 0 0' : 'url("img/icon_increase.png") no-repeat 0 0', backgroundSize: 'cover',
                      }} />
                    </Box>
                  </Box></Box>
                <Box className="box">
                  <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                    <Box sx={{ cursor: "pointer" }}>iPopify Cumulative Orders</Box>
                    <ButtonTooltip title={getButtonTooltipLayout("The number of orders placed by users who have either logged in or registered through iPopify iframes or popups.")} arrow>
                      <Box sx={{
                        width: '15px',
                        height: '15px',
                        background: 'url("img/icon_info.png") no-repeat 0 0',
                        backgroundSize: 'contain',
                      }} />
                    </ButtonTooltip>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                    <Box sx={{ fontWeight: "600", fontSize: "24px", lineHeight: "36px" }}>{data.getDashboardMetaData.ordersCount}</Box>
                  </Box></Box>
              </Box> : <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: "37px",
                padding: "0px 30px",
                marginTop: '20px',
                "& .box": {
                  borderRadius: "16px",
                  background: "#FFF",
                  padding: "24px",
                  border: "1px solid #EFF0F6",
                  width: "250px",
                  height: "112px",
                  boxSizing: "border-box",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "18px",
                  color: "#1C1C1C",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column"
                }
              }}>
                <Box className="box">
                  <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                    <Box sx={{ cursor: "pointer" }}>iPopify Views</Box>
                    <ButtonTooltip title={getButtonTooltipLayout("The total number of times iPopify iframe, and popup are rendered to anonymous users.")} arrow>
                      <Box sx={{
                        width: '15px',
                        height: '15px',
                        background: 'url("img/icon_info.png") no-repeat 0 0',
                        backgroundSize: 'contain',
                      }} />
                    </ButtonTooltip>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                    {loading == false ? <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                      <Box sx={{ cursor: "pointer" }}>Pending</Box>
                      <ButtonTooltip title={getButtonTooltipLayout("Your store's data is currently being gathered. It will be updated and accessible to you within 24 hours.")} arrow>
                        <Box sx={{
                          width: '15px',
                          height: '15px',
                          background: 'url("img/icon_info.png") no-repeat 0 0',
                          backgroundSize: 'contain',
                        }} />
                      </ButtonTooltip>
                    </Box> : <CircularProgress size={"24px"} />}
                  </Box>
                </Box>
                <Box className="box" onClick={() => {
                  if (shopName == null) {
                    return;
                  }
                  window.open(`https://admin.shopify.com/store/${shopName.replace('.myshopify.com', '')}/customers?segment_query=created_by_app_id%20%3D%2083582943233%20`);
                }} sx={{ cursor: "pointer" }}>
                  <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                    <Box sx={{ cursor: "pointer", textDecoration: "underline" }}>iPopify New Customers</Box>
                    <ButtonTooltip title={getButtonTooltipLayout("The number of unique customers who have registered by the iPopify iframe and popup.")} arrow>
                      <Box sx={{
                        width: '15px',
                        height: '15px',
                        background: 'url("img/icon_info.png") no-repeat 0 0',
                        backgroundSize: 'contain',
                      }} />
                    </ButtonTooltip>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                    {loading == false ? <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                      <Box sx={{ cursor: "pointer" }}>Pending</Box>
                      <ButtonTooltip title={getButtonTooltipLayout("Your store's data is currently being gathered. It will be updated and accessible to you within 24 hours.")} arrow>
                        <Box sx={{
                          width: '15px',
                          height: '15px',
                          background: 'url("img/icon_info.png") no-repeat 0 0',
                          backgroundSize: 'contain',
                        }} />
                      </ButtonTooltip>
                    </Box> : <CircularProgress size={"24px"} />}
                  </Box></Box>
                <Box className="box">
                  <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                    <Box sx={{ cursor: "pointer" }}>iPopify Logins</Box>
                    <ButtonTooltip title={getButtonTooltipLayout("The total number of user logins executed through interactions with iPopify iframes or popups.")} arrow>
                      <Box sx={{
                        width: '15px',
                        height: '15px',
                        background: 'url("img/icon_info.png") no-repeat 0 0',
                        backgroundSize: 'contain',
                      }} />
                    </ButtonTooltip>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                    {loading == false ? <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                      <Box sx={{ cursor: "pointer" }}>Pending</Box>
                      <ButtonTooltip title={getButtonTooltipLayout("Your store's data is currently being gathered. It will be updated and accessible to you within 24 hours.")} arrow>
                        <Box sx={{
                          width: '15px',
                          height: '15px',
                          background: 'url("img/icon_info.png") no-repeat 0 0',
                          backgroundSize: 'contain',
                        }} />
                      </ButtonTooltip>
                    </Box> : <CircularProgress size={"24px"} />}
                  </Box></Box>
                <Box className="box">
                  <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                    <Box sx={{ cursor: "pointer" }}>iPopify Cumulative Orders</Box>
                    <ButtonTooltip title={getButtonTooltipLayout("The number of orders placed by users who have either logged in or registered through iPopify iframes or popups.")} arrow>
                      <Box sx={{
                        width: '15px',
                        height: '15px',
                        background: 'url("img/icon_info.png") no-repeat 0 0',
                        backgroundSize: 'contain',
                      }} />
                    </ButtonTooltip>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                    {loading == false ? <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                      <Box sx={{ cursor: "pointer" }}>Pending</Box>
                      <ButtonTooltip title={getButtonTooltipLayout("Your store's data is currently being gathered. It will be updated and accessible to you within 24 hours.")} arrow>
                        <Box sx={{
                          width: '15px',
                          height: '15px',
                          background: 'url("img/icon_info.png") no-repeat 0 0',
                          backgroundSize: 'contain',
                        }} />
                      </ButtonTooltip>
                    </Box> : <CircularProgress size={"24px"} />}
                  </Box></Box>
              </Box>}
              <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: "37px",
                justifyContent: "space-between",
                padding: "0px 30px",
                marginTop: '37px',
                "& .box": {
                  borderRadius: "16px",
                  background: "#FFF",
                  padding: "24px 0px",
                  border: "1px solid #EFF0F6",
                  width: "711px",
                  height: "600px",
                  boxSizing: "border-box"
                }
              }}>
                <Box className="box">
                  <Box sx={{ fontSize: "18px", padding: "0px 30px", lineHeight: "40px" }}>Traffic By Chart</Box>
                  <Box sx={{ display: "flex", alignItems: "center", padding: "0px 30px", gap: '20px', color: "#1C1C1C66" }}>
                    <Box sx={{ color: viewChartEventName == "VIEWS" ? "#1C1C1C" : "1C1C1C66", cursor: "pointer" }} onClick={() => {
                      handleGetDashBoardTable("CHART", "VIEWS");
                      setChartPointsLoading(true);
                      setViewChartEventName("VIEWS");
                    }}>Views</Box>
                    <Box sx={{ color: viewChartEventName == "NEW_CUSTOMERS" ? "#1C1C1C" : "1C1C1C66", cursor: "pointer" }} onClick={() => {
                      handleGetDashBoardTable("CHART", "NEW_CUSTOMERS");
                      setChartPointsLoading(true);
                      setViewChartEventName("NEW_CUSTOMERS");
                    }}>New Customers</Box>
                    <Box sx={{ color: viewChartEventName == "LOGINS" ? "#1C1C1C" : "1C1C1C66", cursor: "pointer" }} onClick={() => {
                      handleGetDashBoardTable("CHART", "LOGINS");
                      setChartPointsLoading(true);
                      setViewChartEventName("LOGINS");
                    }}>Logins</Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: '20px', cursor: "pointer" }} onClick={() => {
                      setIsShowBrokenLineGraphData1(!isShowBrokenLineGraphData1);
                    }}><Box sx={{ width: '10px', height: "10px", background: "#1C1C1C", borderRadius: "50%" }}></Box>{"The " + timeRangeOption}</Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: '20px', cursor: "pointer" }} onClick={() => {
                      setIsShowBrokenLineGraphData2(!isShowBrokenLineGraphData2);
                    }}><Box sx={{ width: '10px', height: "10px", background: "#a8c5da", borderRadius: "50%" }}></Box>{"The Prior " + timeRangeOption}</Box>
                  </Box>
                  {loading == true || chartPointsLoading ? <Box sx={{
                    height: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }} >
                    <CircularProgress />
                  </Box> : null}
                  {loading == false && chartPointsIsNull ? <Box sx={{
                    height: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "18px",
                    lineHeight: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#666",
                    padding: "24px"
                  }} >
                    <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                      <Box sx={{ cursor: "pointer", fontSize: "16px" }}>Pending</Box>
                      <ButtonTooltip title={getButtonTooltipLayout("Your store's data is currently being gathered. It will be updated and accessible to you within 24 hours.")} arrow>
                        <Box sx={{
                          width: '15px',
                          height: '15px',
                          background: 'url("img/icon_info.png") no-repeat 0 0',
                          backgroundSize: 'contain',
                        }} />
                      </ButtonTooltip>
                    </Box>
                  </Box> : null}
                  <Box id="brokenLineGraph" hidden={loading || chartPointsIsNull || chartPointsLoading} sx={{ width: "711px", height: "500px" }}></Box>
                </Box>
                <Box className="box" sx={{ width: "363px!important", padding: "24px!important", alignSelf: "flex-start", height: "600px!important" }}>
                  <Box sx={{ fontSize: "18px", lineHeight: "40px" }}>Traffic By APP</Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: '20px', color: "#1C1C1C66" }}>
                    <Box sx={{ color: viewAppEventName == "VIEWS" ? "#1C1C1C" : "1C1C1C66", cursor: "pointer" }} onClick={() => {
                      handleGetDashBoardTable("IN_APP_BROWSER_NAME", "VIEWS");
                      setAppPointsLoading(true);
                      setViewAppEventName("VIEWS");
                    }}>Views</Box>
                    <Box sx={{ color: viewAppEventName == "NEW_CUSTOMERS" ? "#1C1C1C" : "1C1C1C66", cursor: "pointer" }} onClick={() => {
                      handleGetDashBoardTable("IN_APP_BROWSER_NAME", "NEW_CUSTOMERS");
                      setAppPointsLoading(true);
                      setViewAppEventName("NEW_CUSTOMERS");
                    }}>New Customers</Box>
                    <Box sx={{ color: viewAppEventName == "LOGINS" ? "#1C1C1C" : "1C1C1C66", cursor: "pointer" }} onClick={() => {
                      handleGetDashBoardTable("IN_APP_BROWSER_NAME", "LOGINS");
                      setAppPointsLoading(true);
                      setViewAppEventName("LOGINS");
                    }}>Logins</Box>
                  </Box>
                  {loading == true || AppPointsLoading ? <Box sx={{
                    height: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }} >
                    <CircularProgress />
                  </Box> : null}
                  {loading == false && AppPointsIsNull ? <Box sx={{
                    height: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "18px",
                    lineHeight: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#666",
                  }} >
                    <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                      <Box sx={{ cursor: "pointer", fontSize: "16px" }}>Pending</Box>
                      <ButtonTooltip title={getButtonTooltipLayout("Your store's data is currently being gathered. It will be updated and accessible to you within 24 hours.")} arrow>
                        <Box sx={{
                          width: '15px',
                          height: '15px',
                          background: 'url("img/icon_info.png") no-repeat 0 0',
                          backgroundSize: 'contain',
                        }} />
                      </ButtonTooltip>
                    </Box>
                  </Box> : null}
                  <Box id="traffic_by_app" hidden={loading || AppPointsIsNull || AppPointsLoading} sx={{ width: "315px", height: "500px" }}></Box>
                </Box>
              </Box>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: "37px",
                justifyContent: "space-between",
                padding: "0px 30px",
                marginTop: '37px',
                "& .box": {
                  borderRadius: "16px",
                  background: "#FFF",
                  padding: "24px",
                  border: "1px solid #EFF0F6",
                  width: "250px",
                  height: "112px",
                  boxSizing: "border-box",
                  fontSize: "16px",
                }
              }}>
                <Box className="box" sx={{ width: "646px!important", height: "300px!important" }}>
                  <Box sx={{ fontSize: "18px", height: "32px" }}>Traffic By Device</Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: '20px', color: "#1C1C1C66" }}>
                    <Box sx={{ color: viewOSEventName == "VIEWS" ? "#1C1C1C" : "1C1C1C66", cursor: "pointer" }} onClick={() => {
                      handleGetDashBoardTable("OS", "VIEWS");
                      setOSPointsLoading(true);
                      setViewOSEventName("VIEWS");
                    }}>Views</Box>
                    <Box sx={{ color: viewOSEventName == "NEW_CUSTOMERS" ? "#1C1C1C" : "1C1C1C66", cursor: "pointer" }} onClick={() => {
                      handleGetDashBoardTable("OS", "NEW_CUSTOMERS");
                      setOSPointsLoading(true);
                      setViewOSEventName("NEW_CUSTOMERS");
                    }}>New Customers</Box>
                    <Box sx={{ color: viewOSEventName == "LOGINS" ? "#1C1C1C" : "1C1C1C66", cursor: "pointer" }} onClick={() => {
                      handleGetDashBoardTable("OS", "LOGINS");
                      setOSPointsLoading(true);
                      setViewOSEventName("LOGINS");
                    }}>Logins</Box>
                  </Box>
                  {loading == true || OSPointsLoading ? <Box sx={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }} >
                    <CircularProgress />
                  </Box> : null}
                  {loading == false && OSPointsIsNull ? <Box sx={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "18px",
                    lineHeight: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#666"
                  }} >
                    <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                      <Box sx={{ cursor: "pointer", fontSize: "16px" }}>Pending</Box>
                      <ButtonTooltip title={getButtonTooltipLayout("Your store's data is currently being gathered. It will be updated and accessible to you within 24 hours.")} arrow>
                        <Box sx={{
                          width: '15px',
                          height: '15px',
                          background: 'url("img/icon_info.png") no-repeat 0 0',
                          backgroundSize: 'contain',
                        }} />
                      </ButtonTooltip>
                    </Box>
                  </Box> : null}
                  <Box id="histogram" hidden={loading || OSPointsIsNull || OSPointsLoading} sx={{ width: "598px", height: "200px" }}></Box>
                </Box>
                <Box className="box" sx={{ width: "428px!important", height: "300px!important" }}>
                  <Box sx={{ fontSize: "18px", height: "32px" }}>Traffic By Browser</Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: '20px', color: "#1C1C1C66" }}>
                    <Box sx={{ color: viewBrowserNameEventName == "VIEWS" ? "#1C1C1C" : "1C1C1C66", cursor: "pointer" }} onClick={() => {
                      handleGetDashBoardTable("BROWSER", "VIEWS");
                      setBrowserNameLoading(true);
                      setViewBrowserNameEventName("VIEWS");
                    }}>Views</Box>
                    <Box sx={{ color: viewBrowserNameEventName == "NEW_CUSTOMERS" ? "#1C1C1C" : "1C1C1C66", cursor: "pointer" }} onClick={() => {
                      handleGetDashBoardTable("BROWSER", "NEW_CUSTOMERS");
                      setBrowserNameLoading(true);
                      setViewBrowserNameEventName("NEW_CUSTOMERS");
                    }}>New Customers</Box>
                    <Box sx={{ color: viewBrowserNameEventName == "LOGINS" ? "#1C1C1C" : "1C1C1C66", cursor: "pointer" }} onClick={() => {
                      handleGetDashBoardTable("BROWSER", "LOGINS");
                      setBrowserNameLoading(true);
                      setViewBrowserNameEventName("LOGINS");
                    }}>Logins</Box>
                  </Box>
                  {loading == true || BrowserNameLoading ? <Box sx={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }} >
                    <CircularProgress />
                  </Box> : null}
                  {loading == false && BrowserNameIsNull ? <Box sx={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "18px",
                    lineHeight: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#666"
                  }} >
                    <Box sx={{ display: "flex", gap: "6px", alignItems: 'center' }}>
                      <Box sx={{ cursor: "pointer", fontSize: "16px" }}>Pending</Box>
                      <ButtonTooltip title={getButtonTooltipLayout("Your store's data is currently being gathered. It will be updated and accessible to you within 24 hours.")} arrow>
                        <Box sx={{
                          width: '15px',
                          height: '15px',
                          background: 'url("img/icon_info.png") no-repeat 0 0',
                          backgroundSize: 'contain',
                        }} />
                      </ButtonTooltip>
                    </Box>
                  </Box> : null}
                  <Box id="pieChart" hidden={loading || BrowserNameIsNull || BrowserNameLoading} sx={{ width: "380px", height: "200px" }}></Box>
                </Box>
              </Box>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: "37px",
                justifyContent: "space-between",
                padding: "0px 30px",
                marginTop: '37px',
              }}>
                <Box sx={{
                  padding: "0px 30px",
                  borderRadius: "16px",
                  background: "#FFF",
                  padding: "24px",
                  border: "1px solid #EFF0F6",
                  width: "1111px",
                  minHeight: "500px",
                  boxSizing: "border-box"
                }}>
                  <Box sx={{ fontSize: "18px", height: "32px" }}>Last App History</Box>
                  <Box>
                    <Box sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                      height: '60px',
                      textAlign: "center",
                      fontSize: "16px",
                    }}>
                      <Box sx={{ width: "240px" }}>Date</Box>
                      <Box sx={{ width: "240px" }}>IP</Box>
                      <Box sx={{ width: "170px" }}>First Name</Box>
                      <Box sx={{ width: "170px" }}>Email</Box>
                      <Box sx={{ width: "291px" }}>Event Name</Box>
                    </Box>
                    <Box sx={{
                      "& .item": {
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        borderTop: "1px solid #f0eef1",
                        height: '40px',
                        textAlign: "center",
                        fontSize: "14px",
                      }
                    }}>
                      {loading === false && data != null && data.getDashboardMetaData != null && data.getDashboardMetaData.realTimeEvents != null && data.getDashboardMetaData.realTimeEvents.length > 0 && data.getDashboardMetaData.realTimeEvents.map((item, index) => {
                        return <Box className="item" key={index}>
                          <Box sx={{ width: "240px" }}>{item.date}</Box>
                          <Box sx={{ width: "240px" }}>{item.IP != null ? item.IP : "-"}</Box>
                          <Box sx={{ width: "170px" }}>{item.firstName != null ? item.firstName : "-"}</Box>
                          <Box sx={{ width: "170px" }}>{item.email != null ? item.email : "-"}</Box>
                          <Box sx={{ width: "291px" }}>{item.eventName != null ? item.eventName : "-"}</Box>
                        </Box>
                      })}
                    </Box>
                    {loading === false && data != null && data.getDashboardMetaData != null && data.getDashboardMetaData.realTimeEvents != null && data.getDashboardMetaData.realTimeEvents.length > 10 && false ? <Box sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: '30px'
                    }}>
                      <Box sx={{
                        padding: '6px 16px',
                        borderRadius: "2px",
                        border: "1px solid #e7e5e7",
                        cursor: "pointer"
                      }}>view all history</Box>
                    </Box> : null}
                  </Box>
                </Box>

              </Box>
              <Box sx={{ width: '1px', height: "100px" }}></Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}


