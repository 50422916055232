import React from "react";
import { Suspense, lazy } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Context } from "../Context/Context";
import { Box } from "@mui/system";

const ConnectShopify = lazy(() =>
    import("../SignInComponents/ConnectShopify.react")
);

export default function AccountPopUpDialog() {
    const { state, setState } = React.useContext(Context);
    const { isAccountPopUpDialogOpen, currentComponentName } = state;
    let currentComponent;
    switch (currentComponentName) {
        case 'CONNECT_SHOPIFY':
            currentComponent = (<Suspense fallback={<Box />}>
                <ConnectShopify />
            </Suspense>);
            break;
        default:
            currentComponent = (<Suspense fallback={<Box />}>
                <ConnectShopify />
            </Suspense>);
    }
    return (
        <Dialog
            open={isAccountPopUpDialogOpen ? isAccountPopUpDialogOpen : false}
            onClose={() => {
                setState({
                    ...state,
                    isAccountPopUpDialogOpen: false
                });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                "& .MuiPaper-root": {
                    maxHeight: "none",
                    maxWidth: "none",
                    border: '4px solid #1565d8',
                    borderRadius: '10px',
                },
            }}
        >
            <DialogContent
                sx={{
                    width: "550px",
                    height: "645px",
                    padding: '0'
                }}
            >
                {currentComponent}
            </DialogContent>
        </Dialog>
    )
}