import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Context } from "../Context/Context";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";

export default function SnackbarComponent() {
    const { state, setState } = React.useContext(Context);
    const { openSnackbar, snackbarText, snackbarState, snackbarPosition } = state;
    let snackbarBgColor, snackbarStateIcon;
    switch (snackbarState) {
        case 'SUCCESS':
            snackbarBgColor = '#DFFFDA';
            snackbarStateIcon = '/img/snackbar_icon_success.png'
            break;
        case 'WAIT':
            snackbarBgColor = '#fffdd2';
            snackbarStateIcon = '/img/snackbar_icon_wait.png'
            break;
        case 'FAILED':
            snackbarBgColor = '#ffd0d0';
            snackbarStateIcon = '/img/snackbar_icon_failed.png'
            break;
        default:
            snackbarBgColor = '#DFFFDA';
            snackbarStateIcon = '/img/snackbar_icon_success.png'
    }
    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackbar ? openSnackbar : false}
            // open={true}
            onClose={() => {
                setState({ ...state, openSnackbar: false });
            }}
            message={<React.Fragment>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "30px",
                            height: "30px",
                            marginRight: "10px",
                            background: `url("${snackbarStateIcon}") no-repeat 0 0`,
                            backgroundSize: "contain",
                        }}
                    ></Box>
                    <Box>
                        {snackbarText}
                    </Box>
                </Box>
            </React.Fragment>}
            transitionDuration={500}
            autoHideDuration={500000}
            sx={{
                "&.MuiSnackbar-root": {
                    top: '100px',
                    left: snackbarPosition != null && snackbarPosition.length > 0 ? snackbarPosition : '50%'
                },
                "& .MuiPaper-root": {
                    backgroundColor: "#FFF",
                    justifyContent: "center",
                    width: 'auto',
                    height: "58px",
                    fontFamily: "Arial",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "150%",
                    letterSpacing: "0.15px",
                    color: "#333333",
                    alignItems: "center",
                    boxSizing: 'border-box',
                    minWidth: '0',
                    maxWidth: '1000px',
                    whiteSpace: "nowrap"
                },
                "& .MuiSnackbarContent-message": {
                    padding: "0",
                },
                "& .MuiSnackbarContent-action": {
                    marginLeft: "0",
                },
            }}
        />
    );
}