/** @format */

import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { Context } from "/home/ubuntu/iPopify/src/Context/Context";
import { setCookie } from "doc-cookie";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const queryParams = new URLSearchParams(window.location.search);
const code = queryParams.get("code");
const hmac = queryParams.get("hmac");
const shop = queryParams.get("shop"); //testhffproducts.myshopify.com

const SAVE_TOKEN = gql`
  query Query(
    $email: String
    $shopName: String
    $code: String
    $useCodeForEmail: Boolean
    $domain: String
  ) {
    saveShopifyToken(
      email: $email
      shopName: $shopName
      code: $code
      useCodeForEmail: $useCodeForEmail
      domain: $domain
    ) {
      firstName
      password
      email
      shopName
      token
      shopID
      subscriptionMonthlyURL
      subscriptionYearlyURL
      onboardStep
      showPaymentPage
    }
  }
`;

export default function ShopifyRedirect() {
  const [redirectLink, setRedirectLink] = React.useState(null);
  React.useEffect(() => {
    if (redirectLink != null) {
      window.location = redirectLink;
    }
  }, [redirectLink]);

  if (code != null && hmac != null && shop != null) {
    const domain = window.location.hostname;
    const variables = React.useMemo(() => ({
      shopName: String(shop),
      code: String(code),
      email: null,
      useCodeForEmail: true,
      domain,
    }), [shop, code, domain]);

    //let data = null;
    const { data } = useQuery(SAVE_TOKEN, {
      variables,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      pollInterval: 0, // avoid polling interval
      //notifyOnNetworkStatusChange: true,
    });

    if (data != null && data.saveShopifyToken !== null) {
      // Success saved shopify token, thus login to account
      const { firstName, email, token, shopID, subscriptionMonthlyURL, subscriptionYearlyURL, onboardStep, showPaymentPage } = data.saveShopifyToken;
      localStorage.setItem("firstName", firstName);
      localStorage.setItem("email", email);
      localStorage.setItem("shopName", String(shop));
      localStorage.setItem("shopID", String(shopID));
      localStorage.setItem("subscriptionMonthlyURL", subscriptionMonthlyURL);
      localStorage.setItem("subscriptionYearlyURL", subscriptionYearlyURL);
      localStorage.setItem("onboardStep", onboardStep);
      localStorage.setItem("showPaymentPage", showPaymentPage);

      setCookie({
        key: "token",
        value: token,
        "Max-Age": 604800, // seconds
      });

      // Check enviroment to prevent infinite reloading in shopify mobile app.
      if (redirectLink == null) {
        if (onboardStep == "999") {
          setRedirectLink("/dashboard");
        } else {
          setRedirectLink(`https://${domain}`);
        }
      }
      /*
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // do something magic for mobile
      } else {
        if (subscriptionURL != null && redirectLink == null) {
          setRedirectLink(subscriptionURL);
        } else if (redirectLink == null) {
          setRedirectLink('/onboard?isShopify=1');
        }
      }
    }*/
    }
  }

  return (
    <div>
      <Box sx={{
        height: "400px",
        marginTop: 10,
        display: "flex",
        justifyContent: "center",
      }}
      >
        <CircularProgress />
      </Box>
    </div>
  );
}