/** @format */

import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const UPGRADE_USER_STATUS = gql`
  query upgradeUserStatus($paymentID: String, $type: String) {
    upgradeUserStatus(paymentID: $paymentID, type: $type)
  }
`;

export default function ShopifyPurchaseRedirect() {
    const queryParams = new URLSearchParams(window.location.search);
    const paymentID = queryParams.get('charge_id');
    const type = queryParams.get('type');
    const redirectURL = queryParams.get('redirectURL');
    const subscriptionLinkSource = localStorage.getItem("subscriptionLinkSource");
    // const paymentID = '12345';
    const [redirectLink, setRedirectLink] = React.useState(null);
    React.useEffect(() => {
        if (redirectLink != null) {
            window.location = redirectLink;
        }
    }, [redirectLink]);

    const { loading, data } = useQuery(UPGRADE_USER_STATUS, {
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        variables: {
            paymentID,
            type
        },
    });
    // Monthly: M     Yearly: Y
    // subscriptionStatus === "M1" 升级成为 Monthly:  Success, Upgrade to Pro Monthly Plan.
    // subscriptionStatus === "Y1" 升级成为 Yearly:  Success, Upgrade to Pro Yearly Plan.
    if (loading === false && data != null && data.upgradeUserStatus != null && redirectLink == null) {
        if (data.upgradeUserStatus == true) {
            setRedirectLink((redirectURL == "false" ? (subscriptionLinkSource != null && subscriptionLinkSource.length > 0 ? subscriptionLinkSource : "https://ipopify.com/") : redirectURL) + '?subscriptionStatus=' + (type == "monthly" ? "M1" : "Y1"));
            return;
        } else {
            setRedirectLink((redirectURL == "false" ? (subscriptionLinkSource != null && subscriptionLinkSource.length > 0 ? subscriptionLinkSource : "https://ipopify.com/") : redirectURL) + '?subscriptionStatus=0');
            return;
        }
    }

    return (
        <div>
            <Box sx={{
                height: "400px",
                marginTop: 10,
                display: "flex",
                justifyContent: "center",
            }}
            >
                <CircularProgress />
            </Box>
        </div>
    );
}
