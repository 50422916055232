import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Context } from "../Context/Context";


export default function ExplanatoryVideoDialog() {
    const { state, setState } = React.useContext(Context);
    const { isExplanatoryVideoDialogOpen, explanatoryVideoSrc } = state;
    return (
        <Dialog open={isExplanatoryVideoDialogOpen ? isExplanatoryVideoDialogOpen : false}
            onClose={() => {
                setState({
                    ...state,
                    isExplanatoryVideoDialogOpen: false
                });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                "& .MuiPaper-root": {
                    maxHeight: "none",
                    maxWidth: "none",
                    borderRadius: '10px',
                },
            }}>
            <DialogContent sx={{
                minHeight: "772px",
                padding: '0',
                overflow: 'hidden',
                '& video': {
                    minWidth: "1280px",
                    minHeight: "772px",
                }
            }} >
                <video loop controls autoPlay style={{ "borderRadius": '10px' }}>
                    <source src={explanatoryVideoSrc} />
                </video>
            </DialogContent>
        </Dialog>
    )
}