/** @format */

import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState, useEffect, useRef, useContext } from "react";

import { gql, useQuery, useMutation } from "@apollo/client";

import { Helmet } from "react-helmet";
import { Context } from "../Context/Context";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from "@mui/material/CardMedia";
import { Carousel } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const ENABLE_APP_EMBEDS = gql`
  mutation enableAppEmbeds {
    enableAppEmbeds {
      status
      message
    }
  }
`;

const SAVE_ONBOARD_STEP = gql`
  mutation saveOnboardStep($onboardStep: String) {
    saveOnboardStep(onboardStep: $onboardStep) {
      status
      message
    }
  }
`;

const SAVE_GOOGLE_API_KEY = gql`
  mutation saveGoogleAPIKey($apiKey: String) {
    saveGoogleAPIKey(apiKey: $apiKey) {
      status
      message
    }
  }
`;
const GET_HOME_PAGE_SETTING = gql`
  query getHomePageSetting {
    getHomePageSetting {
      onboardStep
      showPaymentPage
      googleClientID
    }
  }
`;

const ButtonTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement={placement ? placement : "bottom"} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
    fontSize: "20px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    color: "#FFF",
    maxWidth: '260px',
    fontSize: "12px",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 1px rgb(0 0 0 / 12%)",
  },
}));

const getButtonTooltipLayout = (title) => {
  return <React.Fragment>
    <Box sx={{
      color: '#FFF',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '150%'
    }}>
      {title}
    </Box>
  </React.Fragment>
}

export default function HomepageComponents(props) {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [onboardStep, setOnboardStep] = useState(localStorage.getItem('onboardStep'));
  const [showPaymentPage, setShowPaymentPage] = useState(localStorage.getItem('showPaymentPage'));
  const [showGoogleClientID, setShowGoogleClientID] = useState(queryParams.get('subscriptionStatus') != null);
  const shopName = localStorage.getItem("shopName");

  const [googleClientID, setGoogleClientID] = useState("");
  const [originalGoogleClientID, setOriginalGoogleClientID] = useState("");

  const [step, setStep] = useState(0);
  const [firstStepBoxStep, setFirstStepBoxStep] = useState(0);
  const [isAppEmbedsEnabled, setIsAppEmbedsEnabled] = useState(false);
  const [alreadyReCAPTCHA, setAlreadyReCAPTCHA] = useState(false);
  const { setState, state } = useContext(Context);
  const { shopID } = state;
  const [save_onboard_step] = useMutation(SAVE_ONBOARD_STEP);

  window.onresize = function () {
    document.documentElement.scrollLeft = 1920;
  };
  React.useEffect(() => {
    if (!window.location.href.includes('onboard')) {
      if (onboardStep === "999" && sessionStorage.getItem("showGoogleClientID") != "1") {
        navigate("/dashboard");
      }
    }
    // 0 is app embeds, 1 is recapcha page, 2 is payment page, "999" has finished the flow
    if (onboardStep == "0") {
      setStep(0);
      setFirstStepBoxStep(0);
    } else if (onboardStep == "1") {
      setStep(0);
      setFirstStepBoxStep(1);
    } else if (onboardStep == "2" && (showPaymentPage != "false" && showPaymentPage !== false)) {
      setStep(1);
    } else if (onboardStep == "999" && showGoogleClientID) {
      setStep(showPaymentPage != "false" && showPaymentPage !== false ? 2 : 1);
    }
  }, [onboardStep, showPaymentPage]); // Added navigate as a dependency


  const { loading, data } = useQuery(GET_HOME_PAGE_SETTING, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    if (loading == false && data != null && data.getHomePageSetting != null) {
      const { onboardStep, showPaymentPage, googleClientID } = data.getHomePageSetting;
      setOnboardStep(onboardStep);
      setShowPaymentPage(showPaymentPage);
      localStorage.setItem("onboardStep", onboardStep);
      localStorage.setItem("showPaymentPage", showPaymentPage);
      if (googleClientID != null) {
        setGoogleClientID(googleClientID);
        setOriginalGoogleClientID(googleClientID);
      }
    }
  }, [loading, data]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [step]);

  const handleSaveOnboardStep = (onboardStep) => {
    save_onboard_step({
      variables: {
        onboardStep
      }
    });
    localStorage.setItem("onboardStep", onboardStep);
    if (onboardStep == "999") {
      sessionStorage.setItem("showGoogleClientID", "1");
      setShowGoogleClientID(false);
    }
    setOnboardStep(onboardStep);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{
        width: "45%",
        minWidth: '660px',
        padding: '64px 82px',
        background: 'url("img/homepage/homepage_background1.png") no-repeat 0 0',
        backgroundSize: 'cover',
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        position: "sticky",
        top: "0px",
        height: "100vh",
        minHeight: "600px"
      }}>
        <Box sx={{
          width: "500px"
        }}>
          <Box sx={{
            width: '105px', height: '30px', background: 'url("img/logo_white.png") no-repeat 0 0', backgroundSize: 'cover', cursor: "pointer"
          }} onClick={() => {
            navigate("/dashboard");
          }} />
          <Box sx={{ width: "1px", height: '256px' }}></Box>
          <Box sx={{
            width: '25px',
            height: '20px',
            background: 'url("img/quotation_mark_blue.png") no-repeat 0 0',
            backgroundSize: 'contain',
            paddingBottom: "40px"
          }} />
          {step == 0 && shopID != null ? (firstStepBoxStep == 1 ? <Box sx={{
            fontSize: "20px",
            fontWeight: "400",
            fontFamily: "Inter, Roboto",
            lineHeight: "38px",
            color: "#FFF",
            width: "482px"
          }}>
            <Box>reCaptcha is a tool introduced by Google. When reCAPTCHA is enabled, customers who make multiple attempts to log in, create accounts, or reset their passwords are asked to enter a CAPTCHA to make sure they're not a robot.</Box>
          </Box> : <Box>
            <Box sx={{
              fontSize: "30px",
              fontWeight: "700",
              fontFamily: "Inter, Roboto",
              lineHeight: "38px",
              color: "#FFF",
            }}>Welcome to iPopify!</Box>
            <Box sx={{
              width: "482px",
              fontSize: "20px",
              fontWeight: "400",
              fontFamily: "Inter, Roboto",
              lineHeight: "38px",
              color: "#FFF",
              marginTop: '10px'
            }}>We'll take a few steps to set up your account and optimize best customer experience in your store.</Box>
          </Box>) : <Box sx={{
            fontSize: "20px",
            fontWeight: "400",
            fontFamily: "Inter, Roboto",
            lineHeight: "38px",
            color: "#FFF",
            width: "482px"
          }}>
            <Box>Track. Convert. Repeat. </Box>
            <Box>iPopify, the data-driven growth engine for Shopify merchants, delivers the agility and precision needed to excel in today's e-commerce landscape.</Box>
          </Box>}
        </Box>
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        width: "55%",
        minWidth: '763px',
        background: "#FFF",
        alignItems: 'center'
      }}>
        {shopID == null ?
          <Box sx={{
            padding: '64px 120px',
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center"
          }}><Box sx={{
            width: "523px"
          }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: "flex-end",
                gap: '8px'
              }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: "18px",
                  fontWeight: "400",
                  fontFamily: "Inter, Roboto",
                  lineHeight: "28px",
                  color: "#8692A6",
                }}>
                  <Box>Don't have an account?</Box>
                </Box>
                <Box sx={{ color: "#1565D8", fontWeight: "500", textDecoration: 'underline', cursor: "pointer" }} onClick={() => {
                  setState({
                    ...state,
                    isAccountPopUpDialogOpen: true,
                    currentComponentName: 'CONNECT_SHOPIFY',
                  });
                }}>Sign Up</Box>
              </Box>
              <Box sx={{ width: "1px", height: '286px' }}></Box>
              <Box sx={{
                fontSize: "30px",
                fontWeight: "700",
                fontFamily: "Arial",
                lineHeight: "36px",
                color: "#000",
              }}>Join Us!</Box>
              <Box sx={{
                fontSize: "18px",
                fontWeight: "400",
                fontFamily: "Inter, Roboto",
                lineHeight: "28px",
                color: "#8692A6",
                marginTop: '20px'
              }}>To begin this journey, connect to your store!</Box>
              <Box sx={{
                width: "426px",
                height: "108px",
                marginTop: "49px",
                cursor: "pointer",
                border: "1px solid #1565D8",
                borderRadius: "6px",
                background: "#F5F9FF",
                display: "flex",
                alignItems: "center",
                padding: "0px 34px",
                boxSizing: "border-box",
              }} onClick={() => {
                setState({
                  ...state,
                  isAccountPopUpDialogOpen: true,
                  currentComponentName: 'CONNECT_SHOPIFY',
                });
              }}>
                <Box sx={{
                  width: "41px",
                  height: "41px",
                  background: 'url("img/shopify_logo_black.png") no-repeat 0 0',
                  backgroundSize: 'cover',
                }} />
                <Box sx={{
                  width: "264px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "53px",
                  marginLeft: "33px"
                }}>
                  <Box sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "Inter, Roboto",
                    lineHeight: "19px",
                    color: "#000",
                  }}>Shopify</Box>
                  <Box sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "Inter, Roboto",
                    lineHeight: "16px",
                    color: "#8692A6",
                  }}>Connect to your Shopify to track anonymous website visitors, and unlock customer insights.</Box>
                </Box>
                <Box sx={{
                  width: "20px",
                  height: "20px",
                  background: 'url("img/arrow_right_blue.png") no-repeat 0 0',
                  backgroundSize: 'cover',
                }} />
              </Box>
            </Box>
          </Box> :

          (step !== 3 ? <Box sx={{
            padding: '64px 80px',
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            position: "relative"
          }}>
            <Box>
              <Box sx={{ width: showPaymentPage != "false" && showPaymentPage !== false ? '613px' : "450px" }}>
                <Stepper activeStep={step} >
                  <Step>
                    <StepLabel>
                      <Box onClick={() => {
                        setStep(0)
                        setFirstStepBoxStep(0);
                        setIsAppEmbedsEnabled(false);
                        setAlreadyReCAPTCHA(false);
                        handleSaveOnboardStep("0");
                      }} sx={{ cursor: "pointer", fontFamily: "Inter, Roboto" }}>
                        <Box>Enable App Embeds</Box>
                        <Box>step {firstStepBoxStep + 1}/2</Box>
                      </Box>
                    </StepLabel>
                  </Step>
                  {showPaymentPage != "false" && showPaymentPage !== false ? <Step>
                    <StepLabel>
                      <Box onClick={() => {
                        if (step > 0) {
                          setStep(1)
                        }
                        handleSaveOnboardStep("2");
                      }} sx={{ cursor: "pointer" }}>
                        <Box>Choose your plan</Box>
                        <Box>Optional</Box>
                      </Box>
                    </StepLabel>
                  </Step> : null}
                  <Step>
                    <StepLabel>
                      <Box onClick={() => {
                        if (step > 1) {
                          setStep(2)
                        }
                      }} sx={{ cursor: "pointer" }}>
                        <Box>Input Google API</Box>
                        <Box>Highly Recommended</Box>
                      </Box>
                    </StepLabel>
                  </Step>
                </Stepper>
              </Box>
            </Box>
            <Box sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", fontFamily: "Inter, Roboto", margin: "20px 0px", color: "#00000099", cursor: "pointer", color: "#1565D8", textDecoration: 'underline', position: "absolute", top: '10px', right: "0px" }} onClick={() => { navigate("/dashboard") }}>Skip {">>"}</Box>
          </Box> : null)
        }
        <Box hidden={shopID == null} sx={{
          flex: '1',
          width: "100%",
          background: "#F5F9FF",
        }}>
          <Step1
            isHiden={step !== 0}
            firstStepBoxStep={firstStepBoxStep}
            setFirstStepBoxStep={setFirstStepBoxStep}
            handleSaveOnboardStep={handleSaveOnboardStep}
            setStep={setStep}
            shopName={shopName}
            isAppEmbedsEnabled={isAppEmbedsEnabled}
            setIsAppEmbedsEnabled={setIsAppEmbedsEnabled}
            alreadyReCAPTCHA={alreadyReCAPTCHA}
            setAlreadyReCAPTCHA={setAlreadyReCAPTCHA}
          />
          {showPaymentPage != "false" && showPaymentPage !== false ? <Step2
            isHiden={step !== 1}
            firstStepBoxStep={firstStepBoxStep}
            setFirstStepBoxStep={setFirstStepBoxStep}
            handleSaveOnboardStep={handleSaveOnboardStep}
            setStep={setStep}
          /> : null}
          <Step3
            isHiden={showPaymentPage != "false" && showPaymentPage !== false ? step !== 2 : step !== 1}
            firstStepBoxStep={firstStepBoxStep}
            setFirstStepBoxStep={setFirstStepBoxStep}
            handleSaveOnboardStep={handleSaveOnboardStep}
            setStep={setStep}
            googleClientID={googleClientID}
            setGoogleClientID={setGoogleClientID}
            alreadyReCAPTCHA={alreadyReCAPTCHA}
            setAlreadyReCAPTCHA={setAlreadyReCAPTCHA}
            originalGoogleClientID={originalGoogleClientID}
          />


        </Box>
        <FinalStep
          isHiden={step !== 3}
          shopName={shopName}
        />
      </Box>
    </Box >
  );
}

function Step1(props) {
  const {
    isHiden,
    setStep,
    firstStepBoxStep,
    setFirstStepBoxStep,
    shopName,
    handleSaveOnboardStep,
    isAppEmbedsEnabled,
    setIsAppEmbedsEnabled,
    alreadyReCAPTCHA,
    setAlreadyReCAPTCHA,
  } = props;
  const carouselComponent = useRef(null);
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const [enable_app_embeds] = useMutation(ENABLE_APP_EMBEDS);
  return <Box
    hidden={isHiden}
    sx={{
      background: "#F5F9FF",
      width: "100%",
      height: '100%',
      fontFamily: "Inter, Roboto"
    }}
  >
    {firstStepBoxStep == 0 ? <Box sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}>
      <Box sx={{
        marginTop: "25px",
        height: '30px',
        display: "flex",
        alignItems: "center"
      }}>
        <Box sx={{
          width: '24px',
          height: '24px',
          background: isAppEmbedsEnabled ? 'url("img/succeed.png") no-repeat 0 0' : 'url("img/error.png") no-repeat 0 0',
          backgroundSize: 'contain',
        }} />
        <Box sx={{
          width: '569px',
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "28px",
          color: "#000000DE",
          marginLeft: "10px"
        }}>Google One Tap Sign-in requires iPopify App Embeds to work properly.</Box>
      </Box>
      <Box sx={{
        width: '530px',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#00000099"
      }}>With Google One Tap Sign-in, customers can create accounts at your store with only one tap. capeturing crucial customer behavioral data.</Box>
      {!isAppEmbedsEnabled ? <Box sx={{
        width: "280px",
        height: "48px",
        padding: '6px 16px',
        borderRadius: "10px",
        background: "#1565D8",
        boxShadow: "0px 3px 1px -2px #00000033,0px 2px 2px 0px #00000024,0px 1px 5px 0px #0000001F",
        color: "#FFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.4px",
        marginTop: "30px",
        cursor: "pointer"
      }} onClick={() => {
        window.open(`https://${shopName}/admin/themes/current/editor?context=apps&activateAppId=fd724077-30db-4076-9bb6-d5d01c49cd2d/ipopify`);
        setIsAppEmbedsEnabled(true);
      }}>
        <Box sx={{
          width: "28px",
          height: "28px",
          background: 'url("img/shopify_logo_white.png") no-repeat 0 0',
          backgroundSize: 'cover',
        }} />
        <Box>Enable App Embeds</Box>
      </Box> : <Box sx={{
        width: "280px",
        height: "48px",
        padding: '6px 16px',
        borderRadius: "10px",
        background: "#08AD36",
        boxShadow: "0px 3px 1px -2px #00000033,0px 2px 2px 0px #00000024,0px 1px 5px 0px #0000001F",
        color: "#FFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.4px",
        marginTop: "30px",
        cursor: "pointer"
      }} onClick={() => {
        setFirstStepBoxStep(1);
        enable_app_embeds();
        handleSaveOnboardStep("1");
      }}>Next Step</Box>}
      <Box sx={{ height: "381px", width: "720px", pointerEvents: "none" }}>
        <Carousel autoplay ref={carouselComponent} dots={false} style={{ pointerEvents: "none" }} afterChange={(index) => {
          setCurrentCarouselIndex(index);
        }}>
          <Box sx={{ width: "720px", height: "381px", pointerEvents: "none" }}>
            <CardMedia
              component="img"
              image={"img/homepage/web_preview.png"}
              alt="web_preview"
              sx={{ width: "720px", height: "381px", pointerEvents: "none" }}
            />
          </Box>
          <Box sx={{ width: "720px", height: "361px", display: "flex!important", justifyContent: "center", marginTop: "20px", pointerEvents: "none" }}>
            <CardMedia
              component="img"
              image="img/homepage/mobile_web_preview.png"
              alt="mobile_web_preview"
              sx={{ width: "216px!important", height: "361px", pointerEvents: "none" }}
            />
          </Box>
        </Carousel>
      </Box>
      <Box sx={{
        display: "flex",
        gap: "10px",
        marginTop: "10px",
        "&>div": {
          width: "7px",
          height: "7px",
          borderRadius: "5px",
          background: "#adc8ee",
          cursor: "pointer"
        }
      }}>
        <Box sx={{ background: currentCarouselIndex == 0 ? "#1565D8!important" : "#adc8ee" }} onClick={() => {
          if (currentCarouselIndex == 1) {
            carouselComponent.current.prev();
            setCurrentCarouselIndex(0);
          }

        }}></Box>
        <Box sx={{ background: currentCarouselIndex == 1 ? "#1565D8!important" : "#adc8ee" }} onClick={() => {
          if (currentCarouselIndex == 0) {
            carouselComponent.current.next();
            setCurrentCarouselIndex(1);
          }
        }}></Box>
      </Box>
    </Box> : <Box sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}>
      <Box sx={{
        marginTop: "25px",
        height: '30px',
        display: "flex",
        alignItems: "center"
      }}>
        <Box sx={{
          width: '24px',
          height: '24px',
          background: alreadyReCAPTCHA ? 'url("img/succeed.png") no-repeat 0 0' : 'url("img/error.png") no-repeat 0 0',
          backgroundSize: 'contain',
        }} />
        <Box sx={{
          width: '569px',
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "28px",
          color: "#000000DE",
          marginLeft: "10px"
        }}>Uncheck Shopify reCAPTCHA (Optional)</Box>
      </Box>
      <Box sx={{
        width: '530px',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#00000099"
      }}>By unchecking Shopify reCAPTCHA, your store will be much easier for customers to access and customer retention rate will increase. Examples below:</Box>
      <Box sx={{ width: "302px", height: "76px", marginTop: "30px", border: "3px solid #D32F2F" }}>
        <CardMedia
          component="img"
          image={"img/homepage/reCAPTCHA_v2_checkbox.png"}
          alt="reCAPTCHA_v2_checkbox"
          sx={{ width: "302px", height: "76px" }}
        />
      </Box>
      {!alreadyReCAPTCHA ? <Box sx={{
        width: "280px",
        height: "48px",
        padding: '6px 16px',
        borderRadius: "10px",
        background: "#1565D8",
        boxShadow: "0px 3px 1px -2px #00000033,0px 2px 2px 0px #00000024,0px 1px 5px 0px #0000001F",
        color: "#FFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.4px",
        marginTop: "20px",
        cursor: "pointer"
      }} onClick={() => {
        window.open(`https://${shopName}/admin/online_store/preferences`);
        setAlreadyReCAPTCHA(true);
      }}>
        <Box sx={{
          width: "28px",
          height: "28px",
          background: 'url("img/shopify_logo_white.png") no-repeat 0 0',
          backgroundSize: 'cover',
        }} />
        <Box>Uncheck reCAPTCHA</Box>
      </Box> : <Box sx={{
        width: "280px",
        height: "48px",
        padding: '6px 16px',
        borderRadius: "10px",
        background: "#08AD36",
        boxShadow: "0px 3px 1px -2px #00000033,0px 2px 2px 0px #00000024,0px 1px 5px 0px #0000001F",
        color: "#FFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.4px",
        marginTop: "20px",
        cursor: "pointer"
      }} onClick={() => {
        setStep(1);
        handleSaveOnboardStep("2");
      }}>Next Step</Box>}
      <Box sx={{ width: "530px", height: "20px", textAlign: "right", fontSize: "14px", lineHeight: "20px", fontWeight: "400", fontFamily: "Inter, Roboto", margin: "20px 0px", color: "#00000099", cursor: "pointer" }} onClick={() => {
        setStep(1);
        handleSaveOnboardStep("2");
      }}>Skip for now {">>"}</Box>
      <Box sx={{
        width: '530px',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#00000099"
      }}>Please click above button and follow below instructions uncheck reCAPTCHA in Shopify.</Box>
      <Box sx={{
        width: '530px',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#00000099",
        "&>span": {
          fontWeight: "700"
        }
      }}>
        <Box component={"span"}>Step 1: </Box>{" "}
        Head into{" "}
        <Box component={"span"}> Online Store </Box>{" "}
        {"  > "}{" "}
        <Box component={"span"}>Preferences</Box>
      </Box>
      <Box sx={{
        width: '530px',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#00000099",
        "&>span": {
          fontWeight: "700"
        },
      }}>
        <Box component={"span"}>Step 2:</Box>{" "}
        Scroll down to {" "}
        <Box component={"span"}> Spam Protection </Box>{" "}
        and uncheck the boxes upon demand
      </Box>
      <Box sx={{ width: "646px", height: "270px", margin: "20px 0px 30px", }}>
        <CardMedia
          component="img"
          image={"img/homepage/reCAPTCHA_preview.png"}
          alt="reCAPTCHA_preview"
          sx={{ width: "646px", height: "270px" }}
        />
      </Box>
    </Box>}
  </Box>
}

function Step2(props) {
  const {
    isHiden,
    setStep,
    handleSaveOnboardStep
  } = props;
  const [currentPlan, setCurrentPlan] = useState("Yearly");
  const subscriptionMonthlyURL = localStorage.getItem('subscriptionMonthlyURL');
  const subscriptionYearlyURL = localStorage.getItem('subscriptionYearlyURL');

  return <Box
    hidden={isHiden}
    sx={{
      background: "#F5F9FF",
      width: "100%",
      height: '100%',
      fontFamily: "Inter, Roboto"
    }}
  >
    <Box sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}>
      <Box sx={{
        width: "340px",
        height: "70px",
        borderRadius: "10px",
        boxSizing: "border-box",
        padding: "7px 6px",
        display: "flex",
        alignItems: "center",
        background: "#FFF",
        margin: "30px 0px 10px",
        "&>div": {
          width: "160px",
          height: "56px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "18px",
          fontWeight: "500",
          lineHeight: "30px",
          color: "#000",
          background: "#FFF",
          borderRadius: "10px",
          cursor: "pointer"
        },
        "&>.checked": {
          fontSize: "18px",
          fontWeight: "500",
          lineHeight: "30px",
          color: "#FFF",
          background: "#1565D8"
        }
      }}>
        <Box className={currentPlan == "Monthly" ? "checked" : ""} onClick={() => { setCurrentPlan("Monthly") }}>Bill Monthly</Box>
        <Box className={currentPlan == "Yearly" ? "checked" : ""} onClick={() => { setCurrentPlan("Yearly") }}>Bill Yearly</Box>
      </Box>
      <Box sx={{
        width: "374px",
        height: "556px",
        background: '#1565D8',
        borderRadius: "20px"
      }}>
        <Box sx={{
          width: "374px",
          height: "556px",
          background: 'url("img/homepage/plan_background_blue.png") no-repeat 0 105px',
          backgroundSize: 'contain',
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "20px"
        }}>
          <Box sx={{
            marginTop: "42px",
            fontSize: "30px",
            fontWeight: "600",
            lineHeight: "36px",
            color: "#FFF"
          }}>Pro</Box>
          <Box sx={{
            display: "flex",
            marginTop: "35px",
            alignItems: "center",
            width: "127px",
            height: "60px",
            fontSize: "50px",
            fontWeight: "600",
            lineHeight: "30px",
            color: "#FFF"
          }}>
            <Box sx={{
              alignSelf: "flex-start",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "30px",
            }}>$</Box>
            {currentPlan == "Monthly" ? <Box>4.95</Box> : <Box>39.95</Box>}
          </Box>
          {currentPlan == "Monthly" ? <Box sx={{ width: "1px", height: "53px" }}></Box> : <Box sx={{
            marginTop: "13px",
            width: "168px",
            height: "40px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            background: "#1565d8",
            color: "#FFF",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "30px",
          }}>Save $20 a year</Box>}
          <Box sx={{
            marginTop: "17px",
            width: "336px",
            height: "293px",
            padding: "34px 25px",
            borderRadius: "10px",
            boxSizing: "border-box",
            background: "#FFF",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            "& .prerogative": {
              display: "flex",
              alignItems: 'center',
              gap: "10px",
              margin: "22px 0px 0px 10px",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "30px",
              alignSelf: "flex-start"
            }
          }}>
            <Box className="prerogative" sx={{ margin: "0px 0px 0px 10px!important" }}>
              <Box sx={{
                width: "26px",
                height: "26px",
                background: 'url("img/circle_checked_blue.png") no-repeat 0 0',
                backgroundSize: 'cover',
              }} />Enhanced Conversions
            </Box>
            <Box className="prerogative">
              <Box sx={{
                width: "26px",
                height: "26px",
                background: 'url("img/circle_checked_blue.png") no-repeat 0 0',
                backgroundSize: 'cover',
              }} />Popups & Forms
            </Box>
            <Box className="prerogative">
              <Box sx={{
                width: "26px",
                height: "26px",
                background: 'url("img/circle_checked_blue.png") no-repeat 0 0',
                backgroundSize: 'cover',
              }} />24h email support
            </Box>
            {currentPlan == "Monthly" ? <Box sx={{
              width: "287px",
              height: "64px",
              marginTop: "27px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: 'center',
              background: "#1565d8",
              color: "#FFF",
              cursor: "pointer",
              boxShadow: "0px 4px 9px 0px #0000000D",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "30px",
            }} onClick={() => {
              window.open(subscriptionMonthlyURL != null && subscriptionMonthlyURL != "null" ? subscriptionMonthlyURL : "/");
              localStorage.setItem("subscriptionLinkSource", window.location.href);
              setStep(2);
              handleSaveOnboardStep("999");
            }}>Start 7-day free trial</Box> : <Box sx={{
              width: "287px",
              height: "64px",
              marginTop: "27px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: 'center',
              background: "#1565d8",
              color: "#FFF",
              cursor: "pointer",
              boxShadow: "0px 4px 9px 0px #0000000D",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "30px",
            }} onClick={() => {
              window.open(subscriptionYearlyURL != null && subscriptionYearlyURL != "null" ? subscriptionYearlyURL : "/");
              localStorage.setItem("subscriptionLinkSource", window.location.href);
              setStep(2);
              handleSaveOnboardStep("999");
            }}>Save more with Yearly Plan</Box>}
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "1px", height: "20px" }}></Box>
      {/* <Box sx={{ width: "530px", height: "20px", textAlign: "right", fontSize: "14px", lineHeight: "20px", fontWeight: "400", fontFamily: "Inter, Roboto", margin: "20px 0px", color: "#00000099", cursor: "pointer" }} onClick={() => {
        setStep(2);
        handleSaveOnboardStep("999");
      }}>Skip for now {">>"}</Box> */}
    </Box>
  </Box>
}

function Step3(props) {
  const {
    isHiden,
    setStep,
    handleSaveOnboardStep,
    googleClientID,
    setGoogleClientID,
    alreadyReCAPTCHA,
    setAlreadyReCAPTCHA,
    originalGoogleClientID
  } = props;

  const { state, setState } = useContext(Context);
  const [save_google_api_key] = useMutation(SAVE_GOOGLE_API_KEY);

  return <Box
    hidden={isHiden}
    sx={{
      background: "#F5F9FF",
      width: "100%",
      height: '100%',
      fontFamily: "Inter, Roboto"
    }}
  >
    <Box sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}>
      <Box sx={{
        marginTop: "25px",
        height: '30px',
        display: "flex",
        alignItems: "center"
      }}>
        <Box sx={{
          width: '24px',
          height: '24px',
          background: alreadyReCAPTCHA ? 'url("img/succeed.png") no-repeat 0 0' : 'url("img/error.png") no-repeat 0 0',
          backgroundSize: 'contain',
        }} />
        <Box sx={{
          width: '569px',
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "28px",
          color: "#000000DE",
          marginLeft: "10px"
        }}>Import Google API ClientID (<Box sx={{ textDecoration: "underline" }} component={"span"}>Highly Recommended</Box>)</Box>
      </Box>
      <Box sx={{
        width: '530px',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#00000099"
      }}>Inputing Google API Key will help us enhance Google One Tap Sign-in experience by removing your store name with your Google API Key. Visit <Box sx={{ textDecoration: "underline", fontStyle: 'italic', cursor: "pointer" }} onClick={() => { window.open("https://console.cloud.google.com/apis/credentials") }} component={"span"}>https://console.cloud.google.com/apis/credentials</Box> and follow below instructions.</Box>
      <Box sx={{ width: "302px", height: "201px", marginTop: "10px", }}>
        <CardMedia
          component="img"
          image={"img/homepage/google_preview.png"}
          alt="google_preview"
          sx={{ width: "302px", height: "201px" }}
        />
      </Box>
      <Box sx={{
        display: "flex",
        alignItems: 'center',
        gap: "20px"
      }}>
        <TextField
          type={"text"}
          value={googleClientID}
          onChange={(e) => {
            setGoogleClientID(e.target.value);
          }}
          placeholder="Paste Google API ClientID here"
          sx={{
            width: '405px',
            height: '40px',
            borderRadius: '4px',
            background: "#FFF",
            "& .MuiInputBase-root": {
              borderRadius: '4px',
              "&.MuiOutlinedInput-root input": {
                textIndent: '12px',
                height: '40px',
                fontSize: '16px',
                padding: 0,
                color: '#00000099'
              },
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                border: '1px solid #999999'
              },
              "&:hover fieldset": {
                border: '1px solid #999999'
              },
            },

          }}
        />
        <Box sx={{
          width: "105px",
          height: "36px",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: 'center',
          background: "#1565d8",
          color: "#FFF",
          fontSize: "18px",
          fontWeight: "500",
          lineHeight: "24px",
          letterSpacing: "0.4px",
          boxShadow: "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
          cursor: "pointer"
        }} onClick={() => {
          save_google_api_key({
            variables: {
              apiKey: (googleClientID == null || googleClientID.length == 0) ? null : googleClientID,
            }
          }).then((res) => {
            handleSaveOnboardStep("999");
            setStep(3);
            setState({
              ...state,
              openSnackbar: true,
              snackbarText: 'Success! Google Client API Key was submitted successfully',
              snackbarState: 'SUCCESS',
            })
          })
        }}>Submit</Box>
      </Box>
      <Box sx={{ width: "530px", height: "20px", textAlign: "right", fontSize: "14px", lineHeight: "20px", fontWeight: "400", fontFamily: "Inter, Roboto", margin: "20px 0px", color: "#00000099", cursor: "pointer" }} onClick={() => {
        save_google_api_key({
          variables: {
            apiKey: (originalGoogleClientID == null || originalGoogleClientID.length == 0) ? null : originalGoogleClientID,
          }
        })
        handleSaveOnboardStep("999");
        setStep(3);
      }}>Skip for now {">>"}</Box>
      <Box sx={{ width: "717px", height: "414px", margin: "20px 0px 30px", }}>
        <Box sx={{ display: "flex", alignItems: "center", alignItems: "center", justifyContent: "center", width: "717px", height: "414px", background: 'url("/img/homepage/apiKey_video_poster.png") no-repeat 0 0', backgroundSize: 'contain', }}>
          <Box sx={{ width: '80px', height: '80px', background: 'url("/img/icon_product_slide_video_player.png") no-repeat 0 0', backgroundSize: 'contain', cursor: 'pointer' }} onClick={() => {
            setState({
              ...state,
              isExplanatoryVideoDialogOpen: true,
              explanatoryVideoSrc: "/img/homepage/apiKey_preview.mp4"
            })
          }} />
        </Box>
      </Box>
    </Box>
  </Box>
}

function FinalStep(props) {
  const {
    isHiden,
    shopName
  } = props;

  const navigate = useNavigate();

  return <Box
    hidden={isHiden}
    sx={{
      background: "#F5F9FF",
      width: "100%",
      height: '100%',
      fontFamily: "Inter, Roboto"
    }}
  >
    <Box sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}>
      <Box sx={{ marginTop: "300px" }}>
        <Box sx={{
          fontSize: "30px",
          fontWeight: "700",
          fontFamily: "Arial",
          lineHeight: "36px",
          color: "#000",
        }}>Congratulations!</Box>
        <Box sx={{
          fontSize: "18px",
          fontWeight: "400",
          fontFamily: "Inter, Roboto",
          lineHeight: "28px",
          color: "#8692A6",
          marginTop: '20px'
        }}>You are all set! Let us begin the journey with iPopify.</Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
        }}>
          <Box sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
            <Box sx={{
              padding: "6px 26px",
              marginTop: "27px",
              borderRadius: "62px",
              display: "flex",
              alignItems: "center",
              justifyContent: 'center',
              gap: "6px",
              background: "#1565d8",
              color: "#FFF",
              cursor: "pointer",
              boxShadow: "0px 4px 9px 0px #0000000D",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "30px",
            }} onClick={() => {
              window.open(`https://${shopName}`);
            }}>View Google One Tap</Box>
            <ButtonTooltip title={getButtonTooltipLayout("Please use a chrome (not incognito), and have a valid gmail account signed in to test. Otherwise, you may not be able to see the Google One Tap Sign-in. ")} arrow>
              <Box sx={{
                width: '20px',
                height: '20px',
                background: 'url("img/icon_info.png") no-repeat 0 0',
                backgroundSize: 'contain',
                marginTop: "24px",
                transform: "rotate(180deg)"
              }} />
            </ButtonTooltip>
          </Box>
          <Box sx={{
            display: "flex",
          }}>
            <Box sx={{
              padding: "6px 26px",
              marginTop: "27px",
              borderRadius: "62px",
              display: "flex",
              alignItems: "center",
              justifyContent: 'center',
              background: "#1565d8",
              color: "#FFF",
              cursor: "pointer",
              boxShadow: "0px 4px 9px 0px #0000000D",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "30px",
            }} onClick={() => {
              navigate("/dashboard");
            }}>View Dashboard</Box>
          </Box>

        </Box>

      </Box>

    </Box>
  </Box>
}
