/** @format */
import * as React from "react";
import { lazy, useImperativeHandle } from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { Context } from "../Context/Context";
import AppSidebar from "../AppSidebar.react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { countryList } from "../utils/CurrencyList.js";
import CustomSelected from "../CommonComponents/CustomSelected.react";
import { Spin } from 'antd';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "doc-cookie";


// const GET_SETTING_CONTENT = gql`
//   query getSettingContent {
//     getSettingContent {
//       currency
//       priceCalculate
//       timesValue
//       plusValue
//       isTaxable
//       pushProductInformationValue
//       pushProductDescriptionValue
//       language
//       inventoryAutomaticUpdate
//       email
//       isOpenAssignCents
//       AssignCents
//       vendor
//       optimizeValue
//       currentPlanShowDate
//       subscriptionType
//       userID
//       isShowCancel
//       customPricingRule
//       defaultFulfillmentOption
//       productPricingRuleComposition
//     }
//   }
// `;



export default function Forms() {
  const [tabIndex, setTabIndex] = React.useState(0);
  const { state, setState } = React.useContext(Context)
  let { shopName, firstName, sendLog, email, userID } = state;
  shopName = "test.myshopify.com";
  firstName = "zhangsan"
  // const { shopName, firstName, sendLog, email, userID } = state;
  // const [sign_out] = useMutation(SIGN_OUT);
  // const [set_ship_to_country_and_setting_fourPX_shipping_options] = useMutation(SET_SHIP_TO_COUNTRY_AND_SETTING_FOURPX_SHIPPING_OPTIONS);
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const firstNameBox = React.useRef(null);
  const storePlatform = localStorage.getItem("storePlatform");

  // const { loading, data, refetch } = useQuery(GET_SETTING_CONTENT, {
  //   fetchPolicy: "cache-first",
  //   notifyOnNetworkStatusChange: true,
  // });


  // if (shopName == null || firstName == null) {
  //   return (
  //     <>
  //       <AppHeader />
  //       <AppSidebar />
  //       <Box sx={{ display: "flex" }}>
  //         <Box sx={{ width: "240px" }}></Box>
  //         <Box sx={{ flex: "1", padding: "0rem 2rem 0" }}>
  //           <SettingBlankPage />
  //         </Box>
  //       </Box>
  //     </>
  //   );
  // }
  return (
    <>
      <Box>
        <AppSidebar />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "240px" }}></Box>
          <Box sx={{ flex: "1" }}>
            <Box sx={{
              height: "97px",
              borderBottom: "1px solid #dbdbdb",
              display: 'flex',
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 30px"
            }}>
              <Box sx={{
                fontSize: "24px",
                lineHeight: "36px",
                letterSpacing: "0.15px",
                fontWeight: "700",
                color: "#000000DE"
              }}>Forms</Box>

              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  width: "182px",
                  height: "50px",
                  marginLeft: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontSize: "14px",
                  color: '#666666'
                }}
              >
                <Box>
                  <Box
                    aria-controls={openMenu ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={(e) => {
                      setOpenMenu(true);
                      setAnchorEl(e.currentTarget)
                    }}
                    ref={firstNameBox}
                    sx={{ display: "flex", alignItems: "center" }}
                  >{
                      storePlatform != "wix" ? <img
                        src="/img/shopify_logo.png"
                        height="50px"
                        loading={"lazy"}
                        alt="shopify logo"
                      /> : <img
                        src="/img/wix_logo.png"
                        height="45px"
                        loading={"lazy"}
                        alt="wix logo"
                      />
                    }
                    {
                      storePlatform != "wix" ?
                        (shopName != null ? <Box>
                          {shopName.replace("myshopify.com", "")}
                          {shopName.includes("myshopify.com") ? <Box>myshopify.com</Box> : "test.myshopify.com"}
                        </Box> : firstName) :
                        (shopName != null ? <Box sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          WebkitLineClamp: 3,
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                        }}>{shopName}</Box> : firstName)
                    }
                  </Box>
                  <Menu
                    sx={{
                      "& .MuiList-root.MuiList-padding.MuiMenu-list": {
                        width: "220px"
                      }
                    }}
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={(e) => {
                      e.stopPropagation();
                      setAnchorEl(null)
                      setOpenMenu(false);
                    }}
                    TransitionComponent={Fade}
                  >
                    <Box sx={{
                      width: '220px',
                      minHeight: '68px',
                      padding: "8px 16px",
                      boxSizing: "border-box",
                      borderBottom: "1px solid #e0e0e0",
                      "&>div": {
                        lineHeight: '23px',
                      }
                    }}>
                      <Box sx={{
                        fontSize: '16px',
                        color: "rgba(0, 0, 0, 0.87)"
                      }}>{firstName}</Box>
                      <Box sx={{
                        fontSize: '14px',
                        color: "rgba(0, 0, 0, 0.6)"
                      }}>{email}</Box>
                    </Box>
                    {shopName == null && storePlatform != "wix" ? <MenuItem onClick={(e) => {
                      e.stopPropagation();
                      sendLog("CONNECT_TO_SHOPIFY");
                      setState({
                        ...state,
                        isAccountPopUpDialogOpen: true,
                        currentComponentName: 'CONNECT_SHOPIFY',
                      });
                      setAnchorEl(null);
                      setOpenMenu(false);
                    }} sx={{ fontSize: '14px' }}>
                      <img src="img/myProduct/myProduct_icon_push.png" width="24px" height="24px" style={{ marginRight: "32px" }} />Connect to Shopify
                    </MenuItem> : null}
                    <MenuItem onClick={(e) => {
                      e.stopPropagation();
                      navigate("/setting");
                      setAnchorEl(null);
                      setOpenMenu(false);
                    }} sx={{ fontSize: '14px' }}>
                      <SettingsIcon sx={{
                        width: "24px",
                        height: "24px",
                        marginRight: "32px",
                        color: "rgba(0, 0, 0, 0.56)"
                      }} />Setting</MenuItem>
                    {localStorage.getItem("AllowShowSwitchAccountDialog") == "true" && shopName != null && storePlatform != "wix" ? <MenuItem onClick={(e) => {
                      e.stopPropagation();
                      setState({
                        ...state,
                        isOpenSwitchAccountDialog: true
                      });
                      setAnchorEl(null);
                      setOpenMenu(false);
                    }} sx={{ fontSize: '14px' }}>
                      <SyncAltOutlinedIcon sx={{
                        width: "24px",
                        height: "24px",
                        marginRight: "32px",
                        color: "rgba(0, 0, 0, 0.56)"
                      }} />Switch Account</MenuItem> : null}
                    <MenuItem onClick={(e) => {
                      e.stopPropagation();
                      sendLog("SIGN_OUT_CLICK");
                      // localStorage.removeItem("email");
                      // localStorage.removeItem("firstName");
                      // localStorage.removeItem("shopName");
                      // localStorage.removeItem("shopifyToken");
                      // removeCookie('token');
                      // sign_out().then((result) => {
                      //   if (
                      //     result.data != null &&
                      //     result.data.signOut === true
                      //   ) {
                      //     setState({
                      //       ...state,
                      //       openSnackbar: true,
                      //       snackbarText: 'Exit successfully !',
                      //       snackbarState: 'SUCCESS',
                      //     });
                      //   } else {
                      //     setState({
                      //       ...state,
                      //       openSnackbar: true,
                      //       snackbarText: 'Exit failure !',
                      //       snackbarState: 'FAILED',
                      //     });
                      //   }
                      // });
                      // localStorage.clear();
                      // window.location.href = "/app";

                      setAnchorEl(null);
                      setOpenMenu(false);
                    }} sx={{ fontSize: '14px' }}>
                      <LogoutIcon sx={{
                        width: "24px",
                        height: "24px",
                        marginRight: "32px",
                        color: "rgba(0, 0, 0, 0.56)"
                      }} />Sign Out</MenuItem>
                  </Menu>
                </Box>
                <Box sx={{ color: "#707070" }} onClick={() => {
                  firstNameBox.current.click();
                }}>
                  {openMenu ? (
                    <ArrowDropUpIcon sx={{ fontSize: "28px" }} />
                  ) : (
                    <ArrowDropDownIcon sx={{ fontSize: "28px" }} />
                  )}
                </Box>
              </Box>
            </Box>

            <Box sx={{
              padding: "10px 80px"
            }}>Forms</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}


