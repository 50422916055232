/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getCookie } from "doc-cookie";
import ApolloLinkTimeout from 'apollo-link-timeout';

const root = ReactDOM.createRoot(document.getElementById("root"));
const timeoutLink = new ApolloLinkTimeout(60000);

let httpLink = createHttpLink({
  uri: "https://" + window.location.hostname + "/api",
  // uri: "http://ec2-3-89-47-159.compute-1.amazonaws.com/api"
  // uri: "http://ec2-54-162-83-240.compute-1.amazonaws.com/api"
  // uri: "http://ec2-34-238-117-84.compute-1.amazonaws.com/api"
  // uri: "http://localhost:4001/api",
});
const timeoutHttpLink = timeoutLink.concat(httpLink);

const queryParams = new URLSearchParams(window.location.search);
const isDev = queryParams.get("isDev");

if (isDev != null) {
  httpLink = createHttpLink({
    uri: "http://localhost:4001/api",
  });
}

const token = getCookie("token");
const shopName = localStorage.getItem("shopName");

// remove localstorage if cookie expires
if (token == null || token === "null" || token === "undefined") {
  localStorage.clear();
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      token: (token != null) && (token.length > 0) ? token : "",
      // Note this param has to be lower case in header
      shop:
        shopName != null &&
          shopName.length > 0 &&
          token != null &&
          token.length > 0
          ? shopName
          : undefined,
    },
  };
});

// https://www.apollographql.com/docs/react/pagination/core-api/
const cache = new InMemoryCache({});

const client = new ApolloClient({
  link: authLink.concat(timeoutHttpLink),
  cache,
});

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);
