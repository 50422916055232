/** @format */
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { Context } from "../Context/Context";
import AppSidebar from "../AppSidebar.react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Switch from '@mui/material/Switch';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "doc-cookie";
import CardMedia from "@mui/material/CardMedia";
import { CircularProgress } from "@mui/material";


const GET_IPOPIFY_SETTING = gql`
  query getIPopifySetting {
    getIPopifySetting {
      subscriptionType
      googleClientID
      hideIframeOnCart
      showIframe
    }
  }
`;

const SAVE_IPOPIFY_SETTING = gql`
mutation saveIPopifySetting($googleClientID: String, $hideIframeOnCart: String, $showIframe: String) {
    saveIPopifySetting(googleClientID: $googleClientID, hideIframeOnCart: $hideIframeOnCart, showIframe: $showIframe) {
      status
      message
    }
  }
`;

const SAVE_GOOGLE_API_KEY = gql`
  mutation saveGoogleAPIKey($apiKey: String) {
    saveGoogleAPIKey(apiKey: $apiKey) {
      status
      message
    }
  }
`;

const APP_SUBSCRIPTION_CANCEL = gql`
mutation appSubscriptionCancel {
    appSubscriptionCancel {
        status
        message
    }
}
`;



export default function Setting() {
  const navigate = useNavigate();
  const [openChangePlan, setOpenChangePlan] = React.useState(false);
  const { state, setState } = React.useContext(Context)
  const { shopName, firstName, sendLog, email, userID } = state;
  const [showIframe, setShowIframe] = useState(true);
  const [hideIframeOnCart, setHideIframeOnCart] = useState(true);
  const [openApiKeyInput, setOpenApiKeyInput] = useState(false);
  const [googleClientID, setGoogleClientID] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("Free");
  const [initialize, setinitialize] = React.useState(true);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const firstNameBox = React.useRef(null);
  const storePlatform = localStorage.getItem("storePlatform");

  const { loading, data, refetch } = useQuery(GET_IPOPIFY_SETTING, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const [save_ipopify_setting] = useMutation(SAVE_IPOPIFY_SETTING);
  const [save_google_api_key] = useMutation(SAVE_GOOGLE_API_KEY);
  const [app_subscription_cancel] = useMutation(APP_SUBSCRIPTION_CANCEL);


  useEffect(() => {
    if (loading == false && data != null && data.getIPopifySetting != null) {
      setHideIframeOnCart(data.getIPopifySetting.hideIframeOnCart == "1");
      setGoogleClientID(data.getIPopifySetting.googleClientID != null ? data.getIPopifySetting.googleClientID : "");
      setSubscriptionType(data.getIPopifySetting.subscriptionType != null ? data.getIPopifySetting.subscriptionType : "Free");
      setOpenApiKeyInput(data.getIPopifySetting.googleClientID != null && data.getIPopifySetting.googleClientID.length > 0);
      setShowIframe(data.getIPopifySetting.showIframe == "1");
    }
  }, [loading])

  if (loading === true || data == null || data.getIPopifySetting == null) {
    return <Box>
      <AppSidebar />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "240px" }}></Box>
        <Box sx={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Box sx={{
            width: "400px",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <CircularProgress />
          </Box>
        </Box>
      </Box>
    </Box>
  }

  return (
    <>
      <Box>
        <AppSidebar />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "240px" }}></Box>
          <Box sx={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{
              height: "97px",
              width: "100%",
              boxSizing: "border-box",
              borderBottom: "2px solid #dbdbdb",
              display: 'flex',
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 30px"
            }}>
              <Box sx={{
                fontSize: "24px",
                lineHeight: "36px",
                letterSpacing: "0.15px",
                fontWeight: "700",
                color: "#000000DE"
              }}>Settings</Box>
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  width: "182px",
                  height: "50px",
                  marginLeft: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontSize: "14px",
                  color: '#666666'
                }}
              >
                <Box>
                  <Box
                    aria-controls={openMenu ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={(e) => {
                      setOpenMenu(true);
                      setAnchorEl(e.currentTarget)
                    }}
                    ref={firstNameBox}
                    sx={{ display: "flex", alignItems: "center" }}
                  ><img
                      src="/img/shopify_logo.png"
                      height="50px"
                      loading={"lazy"}
                      alt="shopify logo"
                    />
                    {
                      shopName != null ? <Box>
                        {shopName.replace("myshopify.com", "")}
                        {shopName.includes("myshopify.com") ? <Box>myshopify.com</Box> : null}
                      </Box> : firstName
                    }
                  </Box>
                  <Menu
                    sx={{
                      "& .MuiList-root.MuiList-padding.MuiMenu-list": {
                        width: "220px"
                      }
                    }}
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={(e) => {
                      e.stopPropagation();
                      setAnchorEl(null)
                      setOpenMenu(false);
                    }}
                    TransitionComponent={Fade}
                  >
                    <Box sx={{
                      width: '220px',
                      minHeight: '68px',
                      padding: "8px 16px",
                      boxSizing: "border-box",
                      borderBottom: "1px solid #e0e0e0",
                      "&>div": {
                        lineHeight: '23px',
                      }
                    }}>
                      <Box sx={{
                        fontSize: '16px',
                        color: "rgba(0, 0, 0, 0.87)"
                      }}>{firstName}</Box>
                      <Box sx={{
                        fontSize: '14px',
                        color: "rgba(0, 0, 0, 0.6)"
                      }}>{email}</Box>
                    </Box>
                    <MenuItem onClick={(e) => {
                      e.stopPropagation();
                      navigate("/settings");
                      setAnchorEl(null);
                      setOpenMenu(false);
                    }} sx={{ fontSize: '14px' }}>
                      <SettingsIcon sx={{
                        width: "24px",
                        height: "24px",
                        marginRight: "32px",
                        color: "rgba(0, 0, 0, 0.56)"
                      }} />Setting</MenuItem>
                    <MenuItem onClick={(e) => {
                      e.stopPropagation();
                      localStorage.removeItem("email");
                      localStorage.removeItem("firstName");
                      localStorage.removeItem("shopName");
                      localStorage.removeItem("shopifyToken");
                      removeCookie('token');
                      localStorage.clear();
                      window.location.href = "/";
                      setAnchorEl(null);
                      setOpenMenu(false);
                    }} sx={{ fontSize: '14px' }}>
                      <LogoutIcon sx={{
                        width: "24px",
                        height: "24px",
                        marginRight: "32px",
                        color: "rgba(0, 0, 0, 0.56)"
                      }} />Sign Out</MenuItem>
                  </Menu>
                </Box>
                <Box sx={{ color: "#707070" }} onClick={() => {
                  firstNameBox.current.click();
                }}>
                  {openMenu ? (
                    <ArrowDropUpIcon sx={{ fontSize: "28px" }} />
                  ) : (
                    <ArrowDropDownIcon sx={{ fontSize: "28px" }} />
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{
              width: "1111px",
              minHeight: "800px",
              padding: "16px",
              background: "#FFF",
              marginTop: "20px",
              boxSizing: "border-box",
              "& .item-wrap": {
                marginBottom: "20px",
                "& .title": {
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  color: "#000000DE",
                  fontWeight: "400"
                },
                "& .description": {
                  fontSize: "12px",
                  lineHeight: "20px",
                  letterSpacing: "0.4px",
                  color: "#0000008F",
                  fontWeight: "400",
                }

              },
            }}>
              <Box>
                <Box className="item-wrap">
                  <Box sx={{ fontSize: "24px", lineHeight: "36px", letterSpacing: "0.15px", color: "#000000DE", borderBottom: "1px solid #0000001F" }}>iPopify Settings</Box>
                </Box>
                <Box className="item-wrap">
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                    <Box>
                      <Switch checked={showIframe}
                        onChange={() => { setShowIframe(!showIframe) }}
                        inputProps={{ 'aria-label': 'controlled' }} />
                    </Box>
                    <Box className="title">Enable App Embeds</Box>
                  </Box>
                  <Box className="description">Switch on iPopify Google One Tap Sign-in for tracking anonymous visitors and gathering their essential contact details. Plus, enable your customers to effortlessly create accounts at your store with just one tap.</Box>
                </Box>
                <Box className="item-wrap">
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                    <Box>
                      <Switch checked={hideIframeOnCart}
                        onChange={() => { setHideIframeOnCart(!hideIframeOnCart) }}
                        inputProps={{ 'aria-label': 'controlled' }} />
                    </Box>
                    <Box className="title">Hide on Cart, and Checkout Page</Box>
                  </Box>
                  <Box className="description">Disable iPopify Google One Tap Sign-in on the cart and checkout pages to prevent interruptions during the add-to-cart and checkout processes, ensuring a seamless shopping experience for buyers.</Box>
                </Box>
                <Box className="item-wrap">
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                    <Box>
                      <Switch checked={openApiKeyInput}
                        onChange={() => { setOpenApiKeyInput(!openApiKeyInput) }}
                        inputProps={{ 'aria-label': 'controlled' }} />
                    </Box>
                    <Box className="title">Enable Customized Google Experience </Box>
                  </Box>
                  <Box className="description">Input Google API Key will enhance Google One Tap Sign-in experience for your users by replacing iPopify name with your store name set in your Google API Key. Visit <Box component="span" sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { window.open("https://console.cloud.google.com/apis/credentials") }}>https://console.cloud.google.com/apis/credentials</Box> and follow below instruction.</Box>
                </Box>
                {openApiKeyInput ? <Box className="item-wrap">

                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column"
                  }}>
                    <Box sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                      height: "56px"
                    }}>
                      <Box sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                        color: "#000000DE",
                        fontWeight: "700",
                        width: "138px",
                        textAlign: "right"
                      }}>Google API Key</Box>
                      <Box>
                        <TextField
                          type={"text"}
                          value={googleClientID}
                          onChange={(e) => {
                            setGoogleClientID(e.target.value);
                          }}
                          placeholder="Paste Google API Key here"
                          sx={{
                            width: '354px',
                            height: '56px',
                            borderRadius: '4px',
                            "& .MuiInputBase-root": {
                              borderRadius: '4px',
                              "&.MuiOutlinedInput-root input": {
                                textIndent: '12px',
                                height: '56px',
                                fontSize: '16px',
                                fontWeight: "400",
                                letterSpacing: "0.15px",
                                padding: 0,
                                color: '#000000AB'
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                border: '1px solid #0000003B'
                              },
                              "&:hover fieldset": {
                                border: '1px solid #0000003B'
                              },
                            },

                          }}
                        />
                      </Box>
                      <Box sx={{
                        padding: "6px 49px", borderRadius: "10px", background: "#1565D8",
                        boxShadow: "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
                        fontSize: "14px",
                        lineHeight: "24px",
                        letterSpacing: "0.4px",
                        color: "#FFF",
                        fontWeight: "500",
                        cursor: "pointer"
                      }} onClick={() => {
                        save_google_api_key({
                          variables: {
                            apiKey: (googleClientID == null || googleClientID.length == 0) ? null : googleClientID,
                          }
                        }).then((res) => {
                          setState({
                            ...state,
                            openSnackbar: true,
                            snackbarText: 'Success! Google Client API Key was submitted successfully',
                            snackbarState: 'SUCCESS',
                            snackbarPosition: "calc(50% + 120px)"
                          })
                        })
                      }}>Submit</Box>
                    </Box>
                    <Box sx={{ width: "717px", height: "414px", margin: "20px 0px 30px", }}>
                      <Box sx={{ display: "flex", alignItems: "center", alignItems: "center", justifyContent: "center", width: "717px", height: "414px", background: 'url("/img/homepage/apiKey_video_poster.png") no-repeat 0 0', backgroundSize: 'contain', }}>
                        <Box sx={{ width: '80px', height: '80px', background: 'url("/img/icon_product_slide_video_player.png") no-repeat 0 0', backgroundSize: 'contain', cursor: 'pointer' }} onClick={() => {
                          setState({
                            ...state,
                            isExplanatoryVideoDialogOpen: true,
                            explanatoryVideoSrc: "/img/homepage/apiKey_preview.mp4"
                          })
                        }} />
                      </Box>
                    </Box>
                  </Box>
                </Box> : null}
              </Box>
              <Box>
                <Box className="item-wrap">
                  <Box sx={{ fontSize: "24px", lineHeight: "36px", letterSpacing: "0.15px", color: "#000000DE", borderBottom: "1px solid #0000001F" }}>Billing Plan</Box>
                </Box>
                <Box className="item-wrap">
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    color: "#000000DE",
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    fontWeight: "400",
                  }}>
                    <Box sx={{ fontWeight: "700" }}>Current Plan:</Box>
                    <Box>{subscriptionType == "ProMonthly" ? "Billing Monthly" : (subscriptionType == "ProYearly" ? "Billing Yearly" : "Free")}</Box>
                  </Box>
                  {openChangePlan == false ? <Box sx={{
                    display: "flex",
                    marginTop: "20px"
                  }}>
                    <Box sx={{
                      padding: "6px 30px",
                      borderRadius: "10px",
                      background: "#1565D8",
                      boxShadow: "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.4px",
                      color: "#FFF",
                      fontWeight: "500",
                      cursor: "pointer"
                    }} onClick={() => { setOpenChangePlan(true); }}>Change Plan</Box>
                  </Box> :
                    <Box>
                      <Box sx={{ lineHeight: "56px", marginTop: "16px" }}>Change Plan</Box>
                      <Box sx={{ padding: "16px" }}>
                        {subscriptionType == "ProYearly" || subscriptionType == "Free" ? <Box sx={{
                          display: "flex",
                          alignItems: 'center',
                          gap: "10px",
                          cursor: "pointer",
                          width: "728px",
                          height: "72px",
                          border: "1px solid #0000001F",
                          padding: "16px",
                          boxSizing: 'border-box',
                          marginBottom: "16px",
                          "&:hover": {
                            background: "#F5F9FF"
                          }
                        }} onClick={() => { navigate(`/changeSubscription?timeType=monthly&redirectURL=https://${window.location.hostname}/settings`) }}>
                          <Box sx={{
                            width: "24px",
                            height: "24px",
                            background: 'url("img/setting/icon_plan_change.png") no-repeat 0 0',
                            backgroundSize: 'cover',
                          }} />
                          <Box sx={{
                            color: "#000000DE",
                            fontSize: "14px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            fontWeight: "600",
                          }}>Billing Monthly: $4.95/Month</Box>
                        </Box> : null}
                        {subscriptionType == "ProMonthly" || subscriptionType == "Free" ? <Box sx={{
                          display: "flex",
                          alignItems: 'center',
                          gap: "10px",
                          cursor: "pointer",
                          width: "728px",
                          height: "72px",
                          border: "1px solid #0000001F",
                          padding: "16px",
                          boxSizing: 'border-box',
                          marginBottom: "16px",
                          "&:hover": {
                            background: "#F5F9FF"
                          }
                        }} onClick={() => { navigate(`/changeSubscription?timeType=yearly&redirectURL=https://${window.location.hostname}/settings`) }}>
                          <Box sx={{
                            width: "24px",
                            height: "24px",
                            background: 'url("img/setting/icon_plan_change.png") no-repeat 0 0',
                            backgroundSize: 'cover',
                          }} />
                          <Box sx={{
                            color: "#000000DE",
                            fontSize: "14px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            fontWeight: "600",
                          }}>Billing Yearly: 39.95/Year (Save $20!)</Box>
                        </Box> : null}
                        {subscriptionType == "ProMonthly" || subscriptionType == "ProYearly" ? <Box sx={{
                          display: "flex",
                          alignItems: 'center',
                          gap: "10px",
                          cursor: "pointer",
                          width: "728px",
                          height: "72px",
                          border: "1px solid #0000001F",
                          padding: "16px",
                          boxSizing: 'border-box',
                          "&:hover": {
                            background: "#F5F9FF"
                          }
                        }} onClick={() => {
                          app_subscription_cancel().then(result => {
                            if (result != null && result.data != null && result.data.appSubscriptionCancel != null) {
                              if (result.data.appSubscriptionCancel.status) {
                                localStorage.setItem("showPaymentPage", "true");
                                setState({
                                  ...state,
                                  openSnackbar: true,
                                  snackbarText: `Success, Cancel Pro ${subscriptionType == "ProMonthly" ? "Monthly" : "Yearly"} Plan`,
                                  snackbarState: 'SUCCESS',
                                  snackbarPosition: "calc(50% + 120px)"
                                });
                              } else {
                                setState({
                                  ...state,
                                  openSnackbar: true,
                                  snackbarText: `Warning, No active Pro ${subscriptionType == "ProMonthly" ? "Monthly" : "Yearly"} plan found with iPopify`,
                                  snackbarState: 'FAILED',
                                  snackbarPosition: "calc(50% + 120px)"
                                });
                              }
                            } else {
                              setState({
                                ...state,
                                openSnackbar: true,
                                snackbarText: `Warning, No active Pro ${subscriptionType == "ProMonthly" ? "Monthly" : "Yearly"} plan found with iPopify`,
                                snackbarState: 'FAILED',
                                snackbarPosition: "calc(50% + 120px)"
                              });
                            }
                            refetch();
                          })
                        }}>
                          <Box sx={{
                            width: "24px",
                            height: "24px",
                            background: 'url("img/setting/icon_plan_cancel.png") no-repeat 0 0',
                            backgroundSize: 'cover',
                          }} />
                          <Box sx={{
                            color: "#000000DE",
                            fontSize: "14px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            fontWeight: "600",
                          }}>Cancel Current Plan</Box>
                        </Box> : null}
                      </Box>
                    </Box>}
                </Box>
              </Box>
              <Box sx={{ width: "1px", height: "100px" }}></Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                position: 'fixed',
                right: '250px',
                bottom: '50px',
                "& .button": {
                  padding: "6px 49px",
                  borderRadius: "10px",
                  background: "#1565D8",
                  boxShadow: "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
                  fontSize: "14px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  color: "#FFF",
                  fontWeight: "500",
                  cursor: "pointer",
                },
              }}
            >
              <Box className="button" onClick={() => {
                save_ipopify_setting({
                  variables: {
                    googleClientID: openApiKeyInput ? googleClientID : "",
                    hideIframeOnCart: hideIframeOnCart ? "1" : "0",
                    showIframe: showIframe ? "1" : "0"
                  }
                }).then(result => {
                  if (result != null && result.data != null && result.data.saveIPopifySetting != null) {
                    if (result.data.saveIPopifySetting.status) {
                      setState({
                        ...state,
                        openSnackbar: true,
                        snackbarText: 'Save Setting Success !',
                        snackbarState: 'SUCCESS',
                        snackbarPosition: "calc(50% + 120px)"
                      });
                    } else {
                      setState({
                        ...state,
                        openSnackbar: true,
                        snackbarText: 'Save Setting failed !',
                        snackbarState: 'FAILED',
                        snackbarPosition: "calc(50% + 120px)"
                      });
                    }
                  } else {
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText: 'Save Setting failed !',
                      snackbarState: 'FAILED',
                      snackbarPosition: "calc(50% + 120px)"
                    });
                  }
                  refetch();
                })
              }}>
                Save
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}


